<template>
  <ul>
    <li :id="root.fullPath" v-for="root in nodes" :key="root.text">
      <span @click="onRootToggle(root)" class="node">
        <img src="/folder-closed.svg" />
        {{ root.text }}
      </span>
      <ul class="nested">
        <li
          :id="node.fullPath"
          v-for="node in root.directories ?? []"
          :key="node.text"
          class="node"
        >
          <span
            @click="onNodeToggle(node)"
            @dblclick="onDoubleClick(node.fullPath)"
          >
            <img src="/folder-closed.svg" />
            {{ node.text }}
          </span>
        </li>
        <li
          :id="node"
          v-for="node in root.files ?? []"
          :key="node"
          class="node"
        >
          <span @dblclick="onDoubleClick(node)" @click="onFileClick(node)">
            <img src="/file-earmark-fill.svg" />
            {{ node.split("\\").pop() }}
          </span>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { defineComponent, watch, toRefs } from "vue";
import CrudService from "../services/CrudService";

export default defineComponent({
  name: "TreeView",
  props: {
    nodes: {
      required: true,
    },
    selected: {
      required: false,
    },
    extensions: {
      required: false,
    },
  },
  emits: ["doubleClicked", "selectionChanged"],
  setup(props, { emit }) {
    const {
      nodes: nodes,
      selected: selected,
      extensions: extensions,
    } = toRefs(props);

    watch(
      [selected, nodes],
      () => {
        setTimeout(() => {
          loadSelected();
        }, 0);
      },
      { immediate: true }
    );

    async function loadSelected() {
      if (selected.value && nodes.value) {
        const rootDirectory = nodes.value.filter((x) =>
          selected.value.toLowerCase().includes(x.fullPath.toLowerCase())
        )[0];

        let found = false;
        let nextDir = rootDirectory;
        if (!nextDir) return;

        nextDir = await onNodeToggleAsync(nextDir);

        while (!found) {
          const file = nextDir?.files
            ? nextDir.files.filter(
                (x) => x.toLowerCase() === selected.value.toLowerCase()
              )[0]
            : undefined;

          if (file) {
            onFileClick(file);
            found = true;
            continue;
          }

          let next = nextDir.directories.filter((x) =>
            selected.value.toLowerCase().includes(x.fullPath.toLowerCase())
          );
          if (next.length)
            next = next.reduce((t, t2) => (t.length > t2.length ? t : t2));
          else next = next[0];

          if (next) {
            next = await onNodeToggleAsync(next);
          } else {
            found = true;
          }

          nextDir = next;
        }
      }
    }

    function onRootToggle(root) {
      const el = document.getElementById(root.fullPath);
      for (const child of el.children) {
        if (child.nodeName == "SPAN") {
          toggleSvg(child);
        } else {
          child.classList.toggle("active");
        }
      }
      el;
    }

    function toggleSvg(child) {
      const spanImage = child.children[0];

      spanImage.src = spanImage.src.includes("/folder-closed.svg")
        ? spanImage.src.replace("/folder-closed.svg", "/folder-opened.svg")
        : spanImage.src.replace("/folder-opened.svg", "/folder-closed.svg");
    }

    function onNodeToggle(node) {
      if (!node) return;
      CrudService.getDataByQueries("files/folderstructure", [
        {
          queryName: "extensions",
          queryValue: extensions.value,
        },
        {
          queryName: "path",
          queryValue: encodeURIComponent(node.fullPath),
        },
      ]).then((response) => {
        node = response.data[0];
        createNodes(node);
      });
    }

    async function onNodeToggleAsync(node) {
      if (!node) return;
      const response = await CrudService.getDataByQueries(
        "files/folderstructure",
        [
          {
            queryName: "extensions",
            queryValue: extensions.value,
          },
          {
            queryName: "path",
            queryValue: encodeURIComponent(node.fullPath),
          },
        ]
      );
      node = response.data[0];
      createNodes(node);
      return node;
    }

    function createNodes(node) {
      const element = document.getElementById(node.fullPath);

      // It hasn't been created yet because there's only a span inside.
      if (element.children?.length == 1) {
        element.appendChild(createDirectoryList(node));
        element.appendChild(createFileList(node));
      }

      for (const child of element.children) {
        if (child.nodeName == "SPAN") {
          const els = document.getElementsByClassName("selected");
          for (const el of els) {
            el.classList.remove("selected");
          }
          child.classList.add("selected");
          toggleSvg(child);
          emit("selectionChanged", node.fullPath);
        }
        if (child.nodeName == "UL") {
          child.classList.toggle("active");
        }
      }
    }

    function createDirectoryList(node) {
      const list = document.createElement("ul");
      list.classList.add("nested");

      for (const n of node.directories) {
        const item = document.createElement("li");
        item.classList.add("node");
        item.id = n.fullPath;

        const span = document.createElement("span");

        const img = document.createElement("img");
        img.src = "/folder-closed.svg";
        span.appendChild(img);

        span.innerHTML += " " + n.text;

        span.onclick = function () {
          onNodeToggle(n);
        };

        span.ondblclick = () => onDoubleClick(n.fullPath);

        item.appendChild(span);
        list.appendChild(item);
      }

      return list;
    }

    function createFileList(node) {
      const list = document.createElement("ul");
      list.classList.add("nested");

      for (const f of node.files) {
        const item = document.createElement("li");
        item.classList.add("node");
        item.id = f;

        const span = document.createElement("span");

        const img = document.createElement("img");
        img.src = "/file-earmark-fill.svg";
        span.appendChild(img);

        span.innerHTML += " " + f.split("\\").pop();

        span.onclick = () => onFileClick(f);
        span.ondblclick = () => onDoubleClick(f);

        item.appendChild(span);
        list.appendChild(item);
      }

      return list;
    }

    function onFileClick(file) {
      const child = document.getElementById(file);
      const els = document.getElementsByClassName("selected");
      for (const el of els) {
        el.classList.remove("selected");
      }
      child.classList.add("selected");

      emit("selectionChanged", file);
    }

    function onDoubleClick(node) {
      emit("doubleClicked", node);
    }

    return {
      onRootToggle,
      onNodeToggle,
      onFileClick,
      onDoubleClick,
    };
  },
});
</script>

<style>
ul {
  list-style-type: none;
}
li::selection {
  color: #f4ba51;
}

.node {
  user-select: none;
}

.opened-folder {
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.closed-folder {
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.file {
  content: url("../assets/file-icon.jpg");
}

.nested {
  display: none;
}

.active {
  display: block;
}

.selected {
  color: black;
  font-weight: bold;
}
</style>
