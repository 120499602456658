<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Wissellijstregels - {{ machineName }}</strong
          >
        </CCardHeader>
        <CCardBody>
          <CButton
            @click="showWerkelijkeTool"
            :disabled="
              wissellijstRegelsSelectedRow === undefined ||
              !wissellijstRegelsSelectedRow.werkelijkeToolBeschikbaar
            "
            color="light"
            class="btn-md mb-2"
          >
            Bekijk werkelijke tool
          </CButton>
          &nbsp;
          <CButton @click="onExportToPdf" color="light" class="btn-md mb-2">
            PDF
          </CButton>
          &nbsp;
          <CButton
            @click="finishWissellijst"
            :disabled="
              gridRows.filter((row) => !row.werkelijkeToolBeschikbaar).length >
                0 || !locatiesAreUnique
            "
            color="primary"
            class="btn-md mb-2 ms-2"
            style="float: right"
          >
            Wissellijst verwerken
          </CButton>
          &nbsp;
          <dynamic-grid
            name="MWissellijstRegels"
            :metadata="wissellijstRegelsMetadata"
            :data="wissellijstRegelsData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
            :multiSelect="true"
          />
        </CCardBody>
      </CCard>
      <div :style="toolViewerModalActive ? undefined : 'display:none'">
        <tool-viewer-modal
          :werkelijkeTool="wissellijstRegelsSelectedRow?.werkelijkeToolIn"
          :visible="toolViewerModalActive"
          @close="
            () => {
              toolViewerModalActive = false;
            }
          "
        />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import ToolViewerModal from "@/components/ToolViewerModal.vue";
import { computed, toRefs, watch, ref } from "vue";
import WissellijstService from "../../services/WissellijstService";
import { useRouter } from "vue-router";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";
import DownloadClient from "@/services/DownloadClient";
import CrudService from "@/services/CrudService";

export default {
  name: "TabletMachineWissellijsten",
  components: { DynamicGrid, ToolViewerModal, CIcon },
  props: {
    wissellijstId: {
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { wissellijstId: wissellijstId } = toRefs(props);

    const wissellijstRegelsMetadata = computed(
      () => store.state.tabletmachinewissellijst.wissellijstRegelsMetadata
    );
    const wissellijstRegelsData = computed(
      () => store.state.tabletmachinewissellijst.wissellijstRegelsData
    );
    const wissellijstStatus = computed(
      () => store.state.tabletmachinewissellijst.wissellijstStatus
    );

    const machineName = computed(
      () => store.state.tabletmachinewissellijst.machineName
    );

    const gridRows = computed(
      () => store.state.dynamicgrid["MWissellijstRegels"].rows
    );
    const selectedRows = computed(
      () => store.state.dynamicgrid["MWissellijstRegels"].selectedRows
    );
    const oldData = computed(
      () => store.state.tabletmachinewissellijst.oldData
    );
    const oldWissellijstId = computed(
      () => store.state.tabletmachinewissellijst.oldWissellijstId
    );

    const toolViewerModalActive = ref(false);

    const locatiesAreUnique = computed(() => {
      if (!gridRows.value?.length) {
        return false;
      }
      for (let row of gridRows.value) {
        const locaties = gridRows.value.filter(
          (r) => row.locatie && r.locatie == row.locatie
        );
        if (locaties.length > 1) {
          return false;
        }
      }
      return true;
    });

    if (!store.state.dynamicgrid["MWissellijstRegels"]) {
      store.commit("dynamicgrid/SET_NEW_GRID", "MWissellijstRegels");
      store.dispatch("tabletversletentools/getMagazijnenMetadata");
    }

    store.commit("dynamicgrid/SET_NEW_GRID", "MWissellijstRegels");
    store.dispatch("tabletmachinewissellijst/getWissellijstRegelsDataById", {
      wissellijstId: wissellijstId.value,
    });

    watch(wissellijstStatus, () => {
      if (wissellijstStatus.value !== undefined) {
        store.dispatch(
          "tabletmachinewissellijst/getWissellijstRegelsMetadata",
          wissellijstStatus.value
        );
      }
    });

    const wissellijstRegelsSelectedRow = computed(() => {
      if (
        store.state.dynamicgrid["MWissellijstRegels"].selectedRows.length > 0
      ) {
        return store.state.dynamicgrid["MWissellijstRegels"].selectedRows[0];
      }
      return undefined;
    });

    watch(wissellijstRegelsSelectedRow, () => {
      if (wissellijstRegelsSelectedRow.value) {
        store.commit(
          "tabletmachinewissellijst/SET_SELECTED_WISSELLIJST_REGEL_ID",
          wissellijstRegelsSelectedRow.value.id
        );
      }
    });

    watch(
      gridRows,
      (newRows, oldRows) => {
        if (oldRows?.length) {
          if (JSON.stringify(newRows) != JSON.stringify(oldData.value)) {
            if (
              oldWissellijstId.value == -1 ||
              wissellijstId.value == oldWissellijstId.value
            ) {
              store.commit(
                "tabletmachinewissellijst/SET_OLD_DATA",
                JSON.parse(JSON.stringify(newRows))
              );
              store.commit(
                "tabletmachinewissellijst/SET_OLD_WISSELLIJST_ID",
                wissellijstId.value
              );
            }
          }
        }
      },
      { deep: true, immediate: true }
    );

    function printLabels() {
      const labelRows = selectedRows.value.map((x) => x.werkelijkeToolOutId);
      if (!labelRows?.length) {
        router.push({
          name: "werkvloer",
        });
        return;
      }
      CrudService.postData(
        "werkelijketools/printLabels/vervangingen",
        labelRows
      )
        .then((response) => {
          store.commit(
            "HANDLE_RESPONSE",
            {
              response: response.data,
              successMessage: "De labels zijn geprint.",
            },
            { root: true }
          );
          router.push({
            name: "werkvloer",
          });
        })
        .catch((error) => {
          store.commit("SET_ERROR", error, { root: true });
          throw error;
        });
    }

    function showWerkelijkeTool() {
      if (wissellijstRegelsSelectedRow.value.id) {
        store.commit(
          "tabletmachinewissellijst/SET_WERKELIJKE_TOOL_IN",
          wissellijstRegelsSelectedRow.value.werkelijkeToolIn
        );
        toolViewerModalActive.value = true;
      }
    }

    function finishWissellijst() {
      WissellijstService.finish({
        id: wissellijstId.value,
        werkelijkeTools: gridRows.value
          .filter((x) => x.werkelijkeToolOut)
          .map((r) => {
            return {
              werkelijkeToolOutId: r.werkelijkeToolOutId,
              locatie:
                r.locatie == -1 || r.locatie == "Uw Keuze"
                  ? undefined
                  : r.locatie,
              toolnummer: r.toolnummer,
              tmId: r.machinePlaats,
            };
          }),
      })
        .then((response) => {
          store.commit(
            "HANDLE_RESPONSE",
            {
              response: response.data,
              successMessage: "De wissellijst is succesvol verwerkt.",
              displayMultipleErrors: true,
            },
            { root: true }
          );
          if (response.data.success) {
            printLabels();
          }
        })
        .catch((error) => {
          store.commit("ADD_TOAST_ERROR", error, { root: true });
          throw error;
        })
        .finally(() =>
          store.commit("SET_LOADING_STATUS", false, { root: true })
        );
    }

    function goToWerkvloer() {
      router.push({
        name: "werkvloer",
      });
    }

    function onExportToPdf() {
      DownloadClient()
        .get(`wissellijst/download/${wissellijstId.value}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Wissellijst ${machineName.value}`);
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit(
            "ADD_TOAST_SUCCESS",
            "De wissellijst is opgeslagen als pdf.",
            { root: true }
          );
        })
        .catch(() =>
          store.commit(
            "ADD_TOAST_ERROR",
            "De wissellijst kon niet worden geëxporteerd.",
            { root: true }
          )
        );
    }
    return {
      wissellijstRegelsMetadata,
      wissellijstRegelsData,
      wissellijstRegelsSelectedRow,
      showWerkelijkeTool,
      toolViewerModalActive,
      wissellijstStatus,
      gridRows,
      goToWerkvloer,
      cilArrowLeft,
      machineName,
      locatiesAreUnique,
      onExportToPdf,
      finishWissellijst,
    };
  },
};
</script>
