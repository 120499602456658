import CrudService from "../../services/CrudService";

const namespaced = true;

const state = {
  magazijnenMetadata: {},
};

const mutations = {
  SET_MAGAZIJNEN_METADATA(state, payload) {
    payload.properties.push({
      variableName: "printLabel",
      displayName: "Print",
      pinned: true,
      displayType: 10,
      disabledConditions: [
        {
          propertyName: "werkelijkeTool",
          notEmpty: false,
        },
      ],
    });
    state.magazijnenMetadata = payload;
  },

  ADD_FETCHED_DATA(state, { payload, identifier, replaceOldEdits }) {
    if (replaceOldEdits) {
      state[identifier].fetchedData =
        state[identifier].fetchedData.concat(payload);
    } else {
      state[identifier].fetchedData =
        state[identifier].fetchedData.concat(payload);
    }
  },

  ADD_MACHINE_MAGAZIJNEN(state, { payload, identifier }) {
    state[identifier].magazijnenData = payload;
  },

  SET_MACHINE(state, { payload, identifier }) {
    state[identifier].machine = payload;
  },

  SET_NEW_GRID(state, identifier) {
    state[identifier] = {
      magazijnenData: [],
      fetchedData: [],
      machineNaam: undefined,
    };
  },
};

const actions = {
  getMagazijnenMetadata({ commit }) {
    CrudService.getMetadataByName("machinemagazijnen", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "camtl",
          displayName: "CAM nr",
          displayType: 0,
          displayProperties: ["camToolnummer"],
          displayPropertiesPath: ["werkelijkeTool", "tool"],
        });
        response.data.properties.push({
          variableName: "replacement",
          displayName: "Vervanging",
          displayType: 11,
          columnWidth: 200,
          pinned: true,
        });
        response.data.properties.push({
          variableName: "xStatus",
          displayName: "XStatus",
          displayType: 11,
          hide: true,
        });
        response.data.properties.push({
          variableName: "zStatus",
          displayName: "ZStatus",
          displayType: 11,
          hide: true,
        });
        response.data.properties.push({
          variableName: "zLowerLimit",
          displayName: "Z ↓",
          displayType: 0,
        });

        response.data.properties.push({
          variableName: "zWaardeIn",
          displayName: "ZWaarde",
          displayType: 1,
          cellStyle: (params) => {
            if (params.data.xStatus === 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.xStatus === 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        });
        response.data.properties.push({
          variableName: "zUpperLimit",
          displayName: "↑ Z",
          displayType: 0,
        });

        response.data.properties.push({
          variableName: "xLowerLimit",
          displayName: "X ↓",
          displayType: 0,
        });

        response.data.properties.push({
          variableName: "xWaardeIn",
          displayName: "XWaarde",
          displayType: 1,
          cellStyle: (params) => {
            if (params.data.xStatus === 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.xStatus === 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        });
        response.data.properties.push({
          variableName: "xUpperLimit",
          displayName: "↑ X",
          displayType: 0,
        });
        response.data.properties.push({
          variableName: "locatie",
          displayName: "Locatie Uit",
          displayType: 11,
          pinned: true,
        });
        response.data.properties.push({
          variableName: "finished",
          displayName: "Afgerond",
          displayType: 10,
          pinned: true,
          disabledConditions: [
            {
              propertyName: "werkelijkeToolBeschikbaar",
              equals: false,
              or: [
                {
                  propertyName: "replacement",
                  notEmpty: false,
                },
              ],
            },
          ],
        });

        response.data.properties = response.data.properties.map((p) => {
          if (p.variableName == "toolnummer") {
            p.displayType = 11;
          }

          return p;
        });
        commit("SET_MAGAZIJNEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMagazijnenData({ commit }, { machineId, identifier }) {
    CrudService.getDataById("MachineMagazijnen/versleten", machineId)
      .then(async (response) => {
        if (response.data.success) {
          const defaultOptionsInUse = [];
          for (let i = 0; i < response.data.value.length; i++) {
            const m = response.data.value[i];
            m.dropdownConfiguration = [
              {
                options: m.suggestionWerkelijkeTools.map((x) => {
                  if (
                    Number(machineId) != x.referentieMachineId &&
                    x.vastToolnummer
                  ) {
                    x.vastToolnummer = undefined;
                  }

                  return x;
                }),
                displayTextIfNoOptions: "Voorinstellen",
                useDefaultTextAsValue: true,
                class: "form-select form-select-sm mb-3",
                defaultOption: "Voorinstellen",
                isDefaultOptionDisabled: false,
                valuePropertyName: "replacement",
                label: "unieknummer",
              },
            ];
            // This code is to avoid duplicate default values - every row gets its own suggestion
            if (m.dropdownConfiguration[0].options.length > 0) {
              for (
                let o = 0;
                o < m.dropdownConfiguration[0].options.length;
                o++
              ) {
                const option = m.dropdownConfiguration[0].options[o];
                if (defaultOptionsInUse.includes(option.unieknummer)) {
                  continue;
                }
                m.replacement = option.unieknummer;
                defaultOptionsInUse.push(option.unieknummer);
                break;
              }
            }
          }

          response.data.value.map((m, index) => {
            m.id = index + 1000;
            m.werkelijkeToolBeschikbaar = !!m.werkelijkeTool || !!m.replacement;
            m.finished = m.toolId && !m.werkelijkeTool;
            return m;
          });

          commit("ADD_FETCHED_DATA", {
            payload: response.data.value.filter(
              (m) => m.werkelijkeToolBeschikbaar
            ),
            identifier,
          });
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachine({ commit }, { machineId, identifier }) {
    CrudService.getDataById("machines", machineId)
      .then((response) => {
        if (response.data.success) {
          commit("SET_MACHINE", { payload: response.data.value, identifier });
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachineMagazijnenByIds({ commit }, { ids, machineId, identifier }) {
    CrudService.getMany(`machinemagazijnen/${machineId}/ids`, ids)
      .then(async (response) => {
        if (response.data.success) {
          const availableLocations = await CrudService.getGridData(
            `locaties/available?machineId=${machineId}`
          );
          if (!availableLocations.data.success) {
            commit(
              "HANDLE_RESPONSE",
              { response: availableLocations.data },
              { root: true }
            );
            return;
          }
          response.data.value.machineMagazijnenWithSuggestions.forEach(
            (m, index) => {
              m.id = index;

              m.dropdownConfiguration = [
                {
                  options: response.data.value.suggestionWerkelijkeTools.map(
                    (x) => {
                      if (
                        Number(machineId) != x.referentieMachineId &&
                        x.vastToolnummer
                      ) {
                        x.vastToolnummer = undefined;
                      }

                      return x;
                    }
                  ),
                  displayTextIfNoOptions: "Niet beschikbaar",
                  class: "form-select form-select-sm mb-3",
                  isDefaultOptionDisabled: false,
                  valuePropertyName: "replacement",
                  label: "unieknummer",
                },
              ];

              if (m.werkelijkeTool) {
                m.dropdownConfiguration.push({
                  options: [{ code: "Uw Keuze", locatie: -1 }].concat(
                    availableLocations.data.value.locaties
                  ),
                  class: "form-select form-select-sm mb-3",
                  defaultOption: "Uw Keuze",
                  isDefaultOptionDisabled: false,
                  valuePropertyName: "locatie",
                  label: "code",
                  machineProposals:
                    availableLocations.data.value.machineProposals,
                  machineGroepProposals:
                    availableLocations.data.value.machineGroepProposals,
                });
              }
              m.edit = true;
              m.replacement = undefined;
              if (m.werkelijkeTool) {
                m.dropdownConfiguration[0].options = [
                  { unieknummer: "Verwijderen" },
                ];
              } else {
                m.dropdownConfiguration[0].options = [];
              }
              m.dropdownConfiguration[0].options =
                m.dropdownConfiguration[0].options.concat(
                  response.data.value.suggestionWerkelijkeTools
                );
            }
          );

          commit("ADD_FETCHED_DATA", {
            payload: response.data.value.machineMagazijnenWithSuggestions,
            identifier,
            replaceOldEdits: true,
          });
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  printLabels({ commit }, vervangingen) {
    CrudService.postData(
      "werkelijketools/printLabels/vervangingen",
      vervangingen.map((x) => x.werkelijkeToolId)
    )
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "De labels worden geprint.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tabletversletentools = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletversletentools;
