import moment from "moment";

export function getFilterType(type) {
  switch (type) {
    case 0:
      return "agTextColumnFilter";
    case 1:
      return "agNumberColumnFilter";
    case 3:
      return "agTextColumnFilter";
    case 4:
      return "agDateColumnFilter";
    default:
      return "true";
  }
}

export function getDisplayType(property) {
  switch (property.displayType) {
    case 0:
      return "text";
    case 1:
      return "number";
    case 2:
      return "dropdown";
    case 3:
      return "checkbox";
    case 4:
      return property.dateTimePickerType == 0
        ? "date"
        : property.dateTimePickerType == 1
        ? "time"
        : "datetime-local";
    case 5:
      return "file";
    case 6:
      return "multifile";
    case 7:
      return "editor";
    case 8:
      return "foldertree";
  }
}

export function dateComparator(filter, cellValue) {
  if (filter == null) {
    return 0;
  }

  if (filter == "") {
    return 1;
  }

  if (cellValue == "") {
    return -1;
  }

  filter = moment(filter, "DD-MM-yyyy HH:mm");
  const cellDate = moment(cellValue, "DD-MM-yyyy HH:mm");
  if (cellDate.isBefore(filter)) {
    return 1;
  } else if (cellDate.isAfter(filter)) {
    return -1;
  }
  return 0;
}

export function move(value, arrFrom, arrTo) {
  // Helper function to transfer facility from one array to another by 'value' property
  if (!value) return;
  var index = arrFrom.findIndex(function (el) {
    return el.id == value;
  });
  var item = arrFrom[index];
  if (!item) return;
  arrFrom.splice(index, 1);
  if (
    !arrTo.includes(item) &&
    arrTo.filter((x) => x.id && x.id == item.id).length == 0
  ) {
    arrTo.push(item);
  }
}
