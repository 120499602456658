import CrudService from "../../services/CrudService";
import DownloadClient from "../../services/DownloadClient";
import ApiClient from "../../services/ApiClient";
const namespaced = true;

const state = {
  toolsData: [],
  toolsMetadata: {},
  componentsMetadata: {},
  componentsData: [],
  lastImage: {},
};

const mutations = {
  SET_TOOLS_DATA(state, payload) {
    state.toolsData = payload;
  },

  SET_TOOLS_METADATA(state, payload) {
    state.toolsMetadata = payload;
  },

  SET_COMPONENTS_METADATA(state, payload) {
    state.componentsMetadata = payload;
  },

  SET_COMPONENTS_DATA(state, payload) {
    state.componentsData = payload;
  },

  SET_LAST_IMAGE(state, payload) {
    state.lastImage = payload;
  },

  ADD_COMPONENT(state, payload) {
    const componentIndex = state.componentsData.findIndex(
      (c) => c.id == payload.id
    );
    if (componentIndex > 0) {
      state.componentsData[componentIndex] = payload;
    } else {
      state.componentsData.push(payload);
    }
  },

  REMOVE_ROW(state, id) {
    const index = state.componentsData.findIndex(
      (component) => component.id === id
    );
    state.componentsData.splice(index, 1);
  },

  CLEAR_HISTORY(state) {
    state.toolsData = [];
    state.componentsData = [];
    state.lastImage = {};
  },
};

const actions = {
  getComponentsMetadata({ commit }) {
    CrudService.getMetadataByName("components", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "kardex",
          displayName: "Kardex",
          displayType: 10,
          pinned: true,
          disabledConditions: [
            {
              propertyName: "isInKardex",
              equals: true,
            },
            {
              propertyName: "invalidDataForKardex",
              equals: true,
            },
          ],
        });
        commit("SET_COMPONENTS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getComponentsData({ commit }) {
    CrudService.getGridData("components")
      .then((response) => {
        if (response.data.success) {
          response.data.value.forEach((component) => {
            if (
              !component.artikelnummer ||
              !component.barcode ||
              !component.code ||
              !component.omschrijving
            ) {
              component.invalidDataForKardex = true;
            }
            component.kardex = component.isInKardex;
          });
          commit("SET_COMPONENTS_DATA", response.data.value);
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsData({ commit }, componentId) {
    CrudService.getGridData(`tools/byComponent/${componentId}`)
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOLS_DATA", response.data.value);
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsMetadata({ commit }) {
    CrudService.getMetadataByName("tools", "grid")
      .then((response) => {
        commit("SET_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  deleteComponent({ commit }, id) {
    CrudService.deleteById("components", id)
      .then((response) => {
        if (response.data.success) {
          commit("REMOVE_ROW", id);
          commit("SET_TOOLS_DATA", []);
        }

        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage:
              "De geselecteerde component is succesvol verwijderd",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("ADD_TOAST_ERROR", error, { root: true });
        throw error;
      });
  },

  downloadImage({ commit }, componentId) {
    DownloadClient()
      .get(`files?componentId=${componentId}`, { responseType: "arraybuffer" })
      .then((response) => {
        commit(
          "SET_LAST_IMAGE",
          "data:image/.jpeg;base64," +
            Buffer.from(response.data, "binary").toString("base64")
        );
      })
      .catch((error) => {
        commit("ADD_TOAST_ERROR", error, { root: true });
        throw error;
      });
  },

  importeerComponentFromExcel({ commit }, file) {
    const formData = new FormData();
    formData.append("file", file);
    ApiClient()
      .post("files/import/components", formData)
      .then((response) => {
        if (response.data.success) {
          response.data.value.forEach((component) => {
            commit("ADD_COMPONENT", component);
          });
        }
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "Het bestand is succesvol geïmporteerd.",
            displayMultipleErrors: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("ADD_TOAST_ERROR", error, { root: true });
        throw error;
      });
  },

  addToKardex({ commit, rootState }, components) {
    let componentIds = components
      .filter((c) => !c.isInKardex && c.kardex)
      .map((x) => x.id);
    CrudService.postData("kardex/registration", componentIds)
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage:
              "De geselecteerde componenten zijn geregistreerd in Kardex.",
          },
          { root: true }
        );
        rootState.dynamicgrid["CComponents"].rows.forEach((row) => {
          if (componentIds.includes(row.id)) {
            row.isInKardex = true;
            row.kardex = true;
          }
        });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const componentsview = {
  state,
  mutations,
  actions,
  namespaced,
};

export default componentsview;
