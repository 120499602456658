<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Wissellijsten</strong>
        </CCardHeader>
        <CCardBody>
          <CButton
            v-if="hasWriteRole"
            @click="onAdd"
            color="primary"
            class="btn-sm mb-2"
          >
            Wissellijst aanmaken
          </CButton>
          &nbsp;
          <CButton
            v-if="hasWriteRole"
            @click="onExportToPdf"
            :disabled="!wissellijstenSelectedRow"
            color="primary"
            class="btn-sm mb-2"
            >PDF</CButton
          >
          &nbsp;
          <CButton
            v-if="hasWriteRole"
            @click="
              () => {
                deleteModalActive = true;
              }
            "
            :disabled="!wissellijstenSelectedRow?.canDelete"
            color="danger"
            class="btn-sm mb-2 float-end"
          >
            Verwijderen
          </CButton>
          <dynamic-grid
            name="WWissellijsten"
            :metadata="wissellijstenMetadata"
            :data="wissellijstenData"
            :autoPagination="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <strong>Wissellijstregels</strong>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            name="WWissellijstenRegels"
            :metadata="wissellijstenRegelsMetadata"
            :data="wissellijstenRegelsData"
            :autoPagination="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <confirmation-modal
    :style="deleteModalActive ? undefined : 'display:none'"
    :modalActive="deleteModalActive"
    modalBody="Weet u zeker dat u de geselecteerde wissellijst wilt verwijderen?"
    @on-continue="onDelete"
    @on-cancel="() => (deleteModalActive = false)"
  />
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { computed, watch, ref } from "vue";
import { useRouter } from "vue-router";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import DownloadClient from "@/services/DownloadClient";

export default {
  name: "WissellijstenView",
  components: { DynamicGrid, ConfirmationModal },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    store.commit("dynamicgrid/SET_NEW_GRID", "WWissellijsten");
    store.commit("dynamicgrid/SET_NEW_GRID", "WWissellijstenRegels");

    store.commit("wissellijstenview/SET_WISSELLIJSTEN_REGELS_DATA", []);

    store.dispatch("wissellijstenview/getWissellijstenMetadata");
    store.dispatch("wissellijstenview/getWissellijstenData");
    store.dispatch("wissellijstenview/getWissellijstRegelsMetadata");
    store.commit(
      "SET_LAST_PAGE",
      { name: "wissellijstenview" },
      { root: true }
    );

    const deleteModalActive = ref(false);

    const wissellijstenSelectedRow = computed(() => {
      if (store.state.dynamicgrid["WWissellijsten"].selectedRows.length > 0) {
        return store.state.dynamicgrid["WWissellijsten"].selectedRows[0];
      }
      return undefined;
    });

    const wissellijstenRegelsSelectedRow = computed(() => {
      if (
        store.state.dynamicgrid["WWissellijstenRegels"].selectedRows.length > 0
      ) {
        return store.state.dynamicgrid["WWissellijstenRegels"].selectedRows[0];
      }
      return undefined;
    });

    watch(wissellijstenSelectedRow, () => {
      if (wissellijstenSelectedRow.value) {
        store.commit(
          "wissellijstenview/SET_WISSELLIJSTEN_REGELS_DATA",
          wissellijstenSelectedRow.value.regels
        );
      }
    });

    const wissellijstenMetadata = computed(
      () => store.state.wissellijstenview.wissellijstenMetadata
    );
    const wissellijstenData = computed(
      () => store.state.wissellijstenview.wissellijstenData
    );

    const wissellijstenRegelsData = computed(
      () => store.state.wissellijstenview.wissellijstenRegelsData
    );
    const wissellijstenRegelsMetadata = computed(
      () => store.state.wissellijstenview.wissellijstenRegelsMetadata
    );
    const hasWriteRole = computed(
      () => store.state.user?.roles?.includes("wissellijsten") ?? false
    );

    function onAdd() {
      router.push({
        name: "toollijsten",
      });
    }

    function onDelete() {
      store.dispatch(
        "wissellijstenview/deleteWissellijst",
        wissellijstenSelectedRow.value.id
      );
      deleteModalActive.value = false;
    }

    function onExportToPdf() {
      DownloadClient()
        .get(`wissellijst/download/${wissellijstenSelectedRow.value.id}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Wissellijst ${wissellijstenSelectedRow.value.datum}`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit(
            "ADD_TOAST_SUCCESS",
            "De wissellijst is opgeslagen als pdf.",
            { root: true }
          );
        })
        .catch(() =>
          store.commit(
            "ADD_TOAST_ERROR",
            "De wissellijst kon niet worden geëxporteerd.",
            { root: true }
          )
        );
    }

    return {
      wissellijstenMetadata,
      wissellijstenData,
      wissellijstenRegelsData,
      wissellijstenRegelsMetadata,
      wissellijstenSelectedRow,
      wissellijstenRegelsSelectedRow,
      onAdd,
      onDelete,
      deleteModalActive,
      onExportToPdf,
      hasWriteRole,
    };
  },
};
</script>
