<template>
  <router-view v-if="isAuthenticated" />
  <div id="overlay" v-if="loadingStatuses.length > 0">
    <CSpinner
      v-if="loadingStatuses.length > 0"
      label="Loading ..."
      color="primary"
      style="
        position: fixed;
        top: 40%;
        left: 47.5%;
        width: 4rem;
        height: 4rem;
        z-index: 1500;
      "
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch, inject } from "vue";

export default {
  name: "App",
  setup() {
    const store = useStore();
    const toast = inject("toast");

    const isAuthenticated = computed(() => store.state.user.isAuthenticated);
    const loadingStatuses = computed(() => store.state.loadingStatuses);
    const toastErrors = computed(() => store.state.toastErrors);
    const toastSuccesses = computed(() => store.state.toastSuccesses);
    const toastWarnings = computed(() => store.state.toastWarnings);

    function getUserName() {
      return store.state.user.name;
    }

    watch(
      toastErrors,
      () => {
        toast.error(
          toastErrors.value[toastErrors.value.length - 1] ??
            "Oeps, er ging iets fout."
        );
      },
      { deep: true }
    );

    watch(
      toastSuccesses,
      () => {
        toast.success(
          toastSuccesses.value[toastSuccesses.value.length - 1] ?? "Gelukt!"
        );
      },
      { deep: true }
    );

    watch(
      toastWarnings,
      () => {
        const warning = toastWarnings.value[toastWarnings.value.length - 1];
        if (warning) {
          toast.warning(warning);
        }
      },
      { deep: true }
    );

    return {
      isAuthenticated,
      getUserName,
      loadingStatuses,
    };
  },
};
</script>

<style lang="scss">
@import "styles/style";
</style>
