<template>
  <div :style="active ? undefined : 'display:none'">
    <CModal :size="size" :visible="active" @close="onCancel">
      <CModalHeader>
        <CModalTitle>Bevestig de regels buiten de tolerantie</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <dynamic-grid
          name="Measurements"
          :metadata="metadata"
          :data="data"
          :autoPagination="true"
          :multiSelect="true"
        />
      </CModalBody>
      <CModalFooter>
        <CButton @click="onCancel" color="secondary">Annuleren</CButton>
        <CButton
          :disabled="isContinueDisabled"
          @click="onContinue"
          color="primary"
          >Doorgaan</CButton
        >
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import { toRefs, computed } from "vue";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { useStore } from "vuex";

export default {
  name: "MeasurementsModal",
  components: { DynamicGrid },
  props: {
    modalActive: {
      required: true,
    },
    size: {
      required: false,
    },
    rows: {
      required: true,
    },
  },
  emits: ["onContinue", "onCancel"],

  setup(props, { emit }) {
    const { modalActive: active, rows: data } = toRefs(props);

    const store = useStore();
    store.commit("dynamicgrid/SET_NEW_GRID", "Measurements");

    const isContinueDisabled = computed(() => {
      return (
        store.state.dynamicgrid["Measurements"].selectedRows.length !=
        data.value.filter((x) => x.xStatus === 2 || x.zStatus === 2).length
      );
    });
    function onContinue() {
      emit("onContinue");
    }

    function onCancel() {
      emit("onCancel");
    }

    const metadata = {
      properties: [
        {
          variableName: "uniekNummer",
          displayType: 0,
          displayName: "Werkelijke tool",
          displayProperties: ["unieknummer"],
          displayPropertiesPath: ["voorinstelOrderRegel", "werkelijkeTool"],
        },
        {
          variableName: "repair",
          displayName: "Versleten",
          displayType: 3,
          columnWidth: 100,
        },
        {
          variableName: "zLowerLimit",
          displayName: "Z ↓",
          displayType: 1,
          columnWidth: 100,
        },
        {
          variableName: "zWaarde",
          displayProperties: ["zWaarde"],
          displayPropertiesPath: ["voorinstelOrderRegel", "werkelijkeTool"],
          displayName: "Z-waarde",
          displayType: 1,
          columnWidth: 100,
          cellStyle: (params) => {
            if (params.data.zStatus === 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.zStatus === 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        },
        {
          variableName: "zUpperLimit",
          displayName: "↑ Z",
          displayType: 1,
          columnWidth: 100,
        },
        {
          variableName: "xLowerLimit",
          displayName: "X ↓",
          displayType: 1,
          columnWidth: 100,
        },
        {
          variableName: "xWaarde",
          displayProperties: ["xWaarde"],
          displayPropertiesPath: ["voorinstelOrderRegel", "werkelijkeTool"],
          displayName: "X-waarde",
          displayType: 1,
          columnWidth: 100,
          cellStyle: (params) => {
            if (params.data.xStatus === 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.xStatus === 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        },
        {
          variableName: "xUpperLimit",
          displayName: "↑ X",
          displayType: 1,
          columnWidth: 100,
        },
        {
          variableName: "accept",
          displayName: "Accepteer",
          pinned: true,
          displayType: 3,
          selectAll: true,
          disabledConditions: [
            {
              propertyName: "xStatus",
              notEquals: 2,
              and: [
                {
                  propertyName: "zStatus",
                  notEquals: 2,
                },
              ],
            },
          ],
        },
      ],
    };

    return {
      onContinue,
      onCancel,
      active,
      metadata,
      isContinueDisabled,
      data,
    };
  },
};
</script>
