import CrudService from "../../services/CrudService";

const namespaced = true;

const state = {
  registerImportToolsData: [],
  registerImportToolsMetadata: {},
  machineId: undefined,
  machineNaam: "",
};

const mutations = {
  SET_REGISTER_IMPORT_TOOLS_METADATA(state, payload) {
    state.registerImportToolsMetadata = payload;
  },

  SET_REGISTER_IMPORT_TOOLS_DATA(state, payload) {
    state.registerImportToolsData = payload;
  },

  SET_MACHINE_ID(state, payload) {
    state.machineId = payload;
  },

  SET_MACHINE_NAME(state, payload) {
    state.machineNaam = payload;
  },

  CLEAR_HISTORY(state) {
    state.registerImportToolsData = [];
    state.machineNaam = "";
  },
};

const actions = {
  getregisterImportToolsMetadata({ commit }) {
    CrudService.getMetadataByName("toolregisterimporttools", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "toevoegen",
          displayName: "Toevoegen",
          displayType: 3,
          multiSelect: true,
          selectAll: true,
        });
        commit("SET_REGISTER_IMPORT_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tablettoolregisterimport = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tablettoolregisterimport;
