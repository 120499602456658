import CrudService from "../../services/CrudService";
import WissellijstService from "../../services/WissellijstService";

const namespaced = true;

const state = {
  wissellijstRegelsMetadata: [],
  wissellijstRegelsData: {},
  wissellijstRegelId: -1,
  wissellijstStatus: "",
  werkelijkeToolIn: {},
  oldData: [],
  oldWissellijstId: -1,
  machineName: "",
};

const mutations = {
  SET_WISSELLIJST_REGELS_METADATA(state, payload) {
    payload.properties.push({
      variableName: "printLabel",
      displayName: "Print",
      pinned: true,
      displayType: 3,
      selectAll: true,
      disabledConditions: [
        {
          propertyName: "werkelijkeToolOut",
          notEmpty: true,
        },
      ],
    });

    state.wissellijstRegelsMetadata = payload;
  },

  SET_WISSELLIJST_REGELS_DATA(state, payload) {
    state.wissellijstRegelsData = payload;
  },

  SET_SELECTED_WISSELLIJST_REGEL_ID(state, payload) {
    state.wissellijstRegelId = payload;
  },

  SET_WISSELLIJST_STATUS(state, payload) {
    state.wissellijstStatus = payload;
  },

  SET_WERKELIJKE_TOOL_IN(state, payload) {
    state.werkelijkeToolIn = payload;
  },

  SET_OLD_DATA(state, payload) {
    state.oldData = payload;
  },

  SET_OLD_WISSELLIJST_ID(state, wissellijstId) {
    state.oldWissellijstId = wissellijstId;
  },

  SET_MACHINE_NAME(state, machineName) {
    state.machineName = machineName;
  },
};

const actions = {
  getWissellijstRegelsMetadata({ commit }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "wissellijstRegelsMetadata" },
      { root: true }
    );

    CrudService.getMetadataByName("wissellijstregels", "grid")
      .then((response) => {
        response.data.properties = response.data.properties.filter(
          (p) =>
            p.variableName != "sequentialIdInToollijst" &&
            p.variableName != "werkelijkeToolAanwezig" &&
            p.variableName != "werkelijkeToolBeschikbaar"
        );
        response.data.properties.push({
          variableName: "xStatus",
          displayName: "XStatus",
          displayProperties: ["xStatus"],
          displayPropertiesPath: ["measurement"],
          displayType: 11,
          columnWidth: 200,
          hide: true,
        });
        response.data.properties.push({
          variableName: "zStatus",
          displayName: "ZStatus",
          displayProperties: ["zStatus"],
          displayPropertiesPath: ["measurement"],
          displayType: 11,
          columnWidth: 200,
          hide: true,
        });
        response.data.properties.push({
          variableName: "zLowerLimit",
          displayName: "Z ↓",
          displayProperties: ["zLowerLimit"],
          displayPropertiesPath: ["measurement"],
          displayType: 0,
        });
        response.data.properties.push({
          variableName: "zWaardeIn",
          displayName: "ZWaarde",
          displayProperties: ["zWaarde"],
          displayPropertiesPath: ["werkelijkeToolIn"],
          displayType: 1,
          cellStyle: (params) => {
            if (params.data.zStatus == 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.zStatus == 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        });
        response.data.properties.push({
          variableName: "zUpperLimit",
          displayName: "↑ Z",
          displayProperties: ["zUpperLimit"],
          displayPropertiesPath: ["measurement"],
          displayType: 0,
        });
        response.data.properties.push({
          variableName: "xLowerLimit",
          displayName: "X ↓",
          displayProperties: ["xLowerLimit"],
          displayPropertiesPath: ["measurement"],
          displayType: 0,
        });
        response.data.properties.push({
          variableName: "xWaardeIn",
          displayName: "XWaarde",
          displayProperties: ["xWaarde"],
          displayPropertiesPath: ["werkelijkeToolIn"],
          displayType: 1,
          cellStyle: (params) => {
            if (params.data.xStatus == 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.xStatus == 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        });
        response.data.properties.push({
          variableName: "xUpperLimit",
          displayName: "↑ X",
          displayProperties: ["xUpperLimit"],
          displayPropertiesPath: ["measurement"],
          displayType: 0,
        });
        response.data.properties.push({
          variableName: "locatie",
          displayName: "Locatie Uit",
          displayType: 11,
          pinned: true,
        });

        commit("SET_WISSELLIJST_REGELS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "wissellijstRegelsMetadata" },
          { root: true }
        )
      );
  },

  getWissellijstRegelsDataById({ commit }, { wissellijstId }) {
    commit(
      "SET_LOADING_STATUS",
      { status: true, identifier: "wissellijstRegelsData" },
      { root: true }
    );

    WissellijstService.get(wissellijstId)
      .then(async (response) => {
        response.data.value.wissellijst.regels.forEach((regel) => {
          if (regel.werkelijkeToolOut) {
            regel.dropdownConfiguration = {
              options: [{ code: "Uw Keuze" }].concat(
                response.data.value.wissellijst.locaties.map((x) => {
                  return {
                    code: x,
                  };
                })
              ),
              class: "form-select form-select-sm mb-3",
              defaultOption: "Uw Keuze",
              isDefaultOptionDisabled: false,
              valuePropertyName: "locatie",
              label: "code",
            };
          }
        });
        if (
          response.data.value.wissellijst.regels.some(
            (x) =>
              x?.measurement?.xStatus === 2 || x?.measurement?.zStatus === 2
          )
        ) {
          commit(
            "SET_WARNING",
            "Er zijn één of meerdere tools waarbij de X of Z waarde buiten de toleranties valt. U kunt er voor kiezen om deze te accepteren, door de wissellijst te verwerken.",
            { root: true }
          );
        }

        commit(
          "SET_MACHINE_NAME",
          response.data.value.wissellijst.machine.naam
        );
        commit(
          "SET_WISSELLIJST_REGELS_DATA",
          response.data.value.wissellijst.regels
        );
        commit(
          "SET_WISSELLIJST_STATUS",
          response.data.value.wissellijst.status
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      })
      .finally(() =>
        commit(
          "SET_LOADING_STATUS",
          { status: false, identifier: "wissellijstRegelsData" },
          { root: true }
        )
      );
  },
};

const tabletmachinewissellijst = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletmachinewissellijst;
