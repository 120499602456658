import axios from "axios";

export default {
  getConfig() {
    return axios
      .create({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .get("/config.json");
  },
};
