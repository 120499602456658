<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Toollijsten in machine - {{ machineNaam }}</strong
          >
        </CCardHeader>
        <CCardBody>
          <CButton
            @click="goToToollijstTools"
            :disabled="toollijstenSelectedRow === undefined"
            color="primary"
            class="btn-md mb-2"
          >
            Bekijk tools
          </CButton>
          &nbsp;
          <dynamic-grid
            name="MToollijsten"
            :metadata="toollijstenMetadata"
            :data="toollijstenData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { computed, watch, toRefs } from "vue";
import { useRouter } from "vue-router";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";

export default {
  name: "TabletMachineToollijsten",
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId } = toRefs(props);

    if (!store.state.dynamicgrid["MToollijsten"]) {
      store.commit("dynamicgrid/SET_NEW_GRID", "MToollijsten");
      store.dispatch("tabletmachinetoollijsten/getToollijstenMetadata");
    }

    store.dispatch(
      "tabletmachinetoollijsten/getToollijstenData",
      machineId.value
    );

    const machineNaam = computed(
      () => store.state.tabletmachinetoollijsten.machineNaam
    );

    const toollijstenSelectedRow = computed(() => {
      if (store.state.dynamicgrid["MToollijsten"].selectedRows.length > 0) {
        return store.state.dynamicgrid["MToollijsten"].selectedRows[0];
      }
      return undefined;
    });

    watch(
      machineId,
      () => {
        if (machineId.value) {
          store.dispatch(
            "tabletmachinetoollijsten/getMachine",
            machineId.value
          );
        }
      },
      { immediate: true }
    );

    watch(toollijstenSelectedRow, () => {
      if (toollijstenSelectedRow.value) {
        store.commit(
          "tabletmachinetoollijsten/SET_SELECTED_TOOLLIJST_ID",
          toollijstenSelectedRow.value.id
        );
      }
    });

    const toollijstenMetadata = computed(
      () => store.state.tabletmachinetoollijsten.toollijstenMetadata
    );
    const toollijstenData = computed(
      () => store.state.tabletmachinetoollijsten.toollijstenData
    );

    function goToToollijstTools() {
      if (toollijstenSelectedRow.value.id) {
        router.push({
          name: "tablettoollijsttools",
          params: {
            toollijstId: toollijstenSelectedRow.value.id,
            machineId: machineId.value,
          },
        });
      }
    }

    function goToWerkvloer() {
      router.push({
        name: "werkvloer",
      });
    }

    return {
      toollijstenMetadata,
      toollijstenData,
      toollijstenSelectedRow,
      goToToollijstTools,
      cilArrowLeft,
      goToWerkvloer,
      machineNaam,
    };
  },
};
</script>
