<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img :src="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav>
        <CDropdown autoClose="outside" variant="nav-item">
          <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
            {{ username }}
          </CDropdownToggle>
          <CDropdownMenu class="pt-0">
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
              Printer Details
            </CDropdownHeader>
            <CDropdownItem>
              <select
                class="form-select form-select-sm mt-1 mb-1"
                @change="onPrinterChanged"
                size="sm"
                style="width: 200px"
                :value="[
                  printerSelected?.printerName
                    ? printerSelected?.printerName
                    : 'default',
                ]"
              >
                <option value="default" selected>Kies een printer</option>
                <option
                  v-for="printer in availablePrinters"
                  :key="printer?.printerName"
                >
                  {{ printer?.printerName }}
                </option>
              </select>
              <CLabel>{{ printerSelected?.ipAddress }}</CLabel>
            </CDropdownItem>
            <CDropdownDivider />
            <CDropdownItem @click="logout">
              <CIcon icon="cil-lock-locked" /> Logout
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import { inject, computed, watch, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "AppHeader",
  components: {},
  setup() {
    const store = useStore();
    let keycloak = inject("keycloak");

    const username = computed(() => store.state.user?.name);
    const selectedPrinter = computed(() => store.state.selectedPrinter);
    const availablePrinters = computed(() => store.state.availablePrinters);

    const printerSelected = ref(undefined);

    watch(
      username,
      () => {
        if (username.value) {
          store.dispatch("getAllPrinters", { root: true });
          store.dispatch("getSelectedPrinter", username.value, { root: true });
        }
      },
      { immediate: true }
    );

    watch(
      selectedPrinter,
      () => {
        if (selectedPrinter.value?.printer) {
          printerSelected.value = selectedPrinter.value.printer;
        }
      },
      { immediate: true }
    );

    function onPrinterChanged(event) {
      const pr = availablePrinters.value.filter(
        (p) => p.printerName == event.target.value
      )[0];
      if (!selectedPrinter.value?.id) {
        store.dispatch(
          "addPrinter",
          { userName: username.value, printer: pr, printerId: pr.id },
          { root: true }
        );
      } else {
        store.dispatch(
          "updatePrinter",
          {
            id: selectedPrinter.value.id,
            userName: username.value,
            printer: pr,
            printerId: pr.id,
          },
          { root: true }
        );
      }
    }

    function logout() {
      store.commit("logout");
      keycloak.logout();
    }

    return {
      logo: computed(() => store.state.logo),
      onPrinterChanged,
      logout,
      username,
      selectedPrinter,
      availablePrinters,
      printerSelected,
    };
  },
};
</script>
