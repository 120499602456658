<template>
  <confirmation-modal
    :style="deleteModalActive ? undefined : 'display:none'"
    :modalActive="deleteModalActive"
    :modalBody="modalBody"
    @on-continue="onDelete"
    @on-cancel="() => (deleteModalActive = false)"
  />
  <measurements-modal
    :modalActive="measurementsToConfirm?.length > 0"
    :rows="measurementsToConfirm"
    size="xl"
    @on-continue="confirmMeasurements"
    @on-cancel="cancelMeasurements"
  />

  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Voorinstelregels</strong>
        </CCardHeader>
        <CCardBody style="display: inline-grid">
          <div class="mb-1">
            <CButton
              v-if="hasWriteRole"
              @click="onCreateOrder"
              :disabled="!voorinstelRegelsSelectedRows?.length"
              color="primary"
              class="btn-sm mb-2"
            >
              Voorinstelorder aanmaken
            </CButton>
            <CButton
              v-if="hasWriteRole"
              @click="
                () => {
                  isDeleteOrder = false;
                  modalBody = getRegelsModalBody();
                  deleteModalActive = true;
                }
              "
              :disabled="!voorinstelRegelsSelectedRows?.length"
              color="danger"
              class="btn-sm mb-2"
              style="float: right; margin-left: 10px"
            >
              Verwijderen
            </CButton>
            &nbsp;
            <select
              class="form-select form-select-sm"
              @change="onMachineChange"
              size="sm"
              style="width: 300px; float: right"
              :value="selectedMachineId"
            >
              <option value="-1">Kies een Machine</option>
              <option
                v-for="machine in machines"
                :value="machine.id"
                :key="machine"
              >
                {{ machine.naam }}
              </option>
            </select>
            &nbsp;
          </div>
          <dynamic-grid
            @data-changed="selectRegelsOnDataChange"
            name="VVoorinstelRegels"
            :metadata="voorinstelRegelsMetadata"
            :data="selectedRegelsData"
            :autoPagination="true"
            :multiSelect="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Voorinstelorders</strong>
        </CCardHeader>
        <CCardBody>
          <CButton
            v-if="hasWriteRole"
            @click="changeStatus(1)"
            :disabled="
              !voorinstelOrdersSelectedRow ||
              voorinstelOrdersSelectedRow?.status != 0 ||
              !areAllQrTextFieldsFilledInForVoorinstelOrderRegels
            "
            color="primary"
            class="btn-sm mb-2"
          >
            Monteren
          </CButton>
          &nbsp;
          <CButton
            v-if="hasWriteRole"
            @click="changeStatus(2)"
            :disabled="
              !voorinstelOrdersSelectedRow ||
              voorinstelOrdersSelectedRow?.status < 1 ||
              voorinstelOrdersSelectedRow?.status > 3 ||
              !areAllQrTextFieldsFilledInForVoorinstelOrderRegels
            "
            color="primary"
            class="btn-sm mb-2"
          >
            Meten
          </CButton>
          &nbsp;
          <CButton
            v-if="hasWriteRole"
            @click="changeStatus(3, false)"
            :disabled="
              !voorinstelOrdersSelectedRow ||
              voorinstelOrdersSelectedRow?.status != 2 ||
              !areAllQrTextFieldsFilledInForVoorinstelOrderRegels
            "
            color="primary"
            class="btn-sm mb-2"
          >
            Meten Afgerond
          </CButton>
          &nbsp;
          <CButton
            v-if="hasWriteRole"
            @click="changeStatus(4)"
            :disabled="
              !voorinstelOrdersSelectedRow ||
              voorinstelOrdersSelectedRow?.status != 3 ||
              !areAllQrTextFieldsFilledInForVoorinstelOrderRegels
            "
            color="primary"
            class="btn-sm mb-2"
          >
            Voorinstellen Afgerond
          </CButton>
          <div class="float-end">
            <CButton
              @click="exportPdf"
              :disabled="!voorinstelOrdersSelectedRow"
              color="light"
              class="btn-sm mb-2"
            >
              PDF
            </CButton>
            &nbsp;

            <CButton
              v-if="hasWriteRole"
              @click="
                () => {
                  isDeleteOrder = true;
                  modalBody = getModalBodyText();
                  deleteModalActive = true;
                }
              "
              :disabled="!voorinstelOrdersSelectedRow"
              color="danger"
              class="btn-sm mb-2"
            >
              Verwijderen
            </CButton>
          </div>
          <dynamic-grid
            name="VVoorinstelOrders"
            @data-changed="onVoorinstelOrdersDataChanged"
            :metadata="voorinstelOrdersMetadata"
            :data="selectedOrdersData"
            :autoPagination="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Voorinstelorderregels</strong>
        </CCardHeader>
        <CCardBody>
          <CButton
            v-if="hasWriteRole"
            @click="updateQrCodesAndLocations"
            :disabled="
              !voorinstelOrderRegelsData.length || updateLocationsDisabled
            "
            color="primary"
            class="btn-sm mb-2"
          >
            Opslaan
          </CButton>
          &nbsp;
          <div class="float-end">
          <CButton
              v-if="hasWriteRole"
              @click="printLabels(false)"
              :disabled="!voorinstelOrderRegelsGridRows?.filter(x=>x.print)?.length || !isPrinterSelected"
              color="light"
              class="btn-sm mb-2"
            >
                Geselecteerde labels printen
          </CButton>
          &nbsp;
          <CButton
              v-if="hasWriteRole"
              :disabled="!voorinstelOrderRegelsGridRows?.length || !isPrinterSelected"
              @click="printLabels(true)"
              color="light"
              class="btn-sm mb-2"
            >
              Alle labels printen
          </CButton>
          </div>
          <dynamic-grid
            name="VVoorinstelOrderRegels"
            :metadata="voorinstelOrderRegelsMetadata"
            heightOfRows="35"
            :data="voorinstelOrderRegelsData"
            :autoPagination="true"
            :maxColumnsBeforeOverflow="0"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import MeasurementsModal from "@/components/MeasurementsModal.vue";
import { computed, watch, ref, toRefs } from "vue";
import DownloadClient from "@/services/DownloadClient";
import moment from "moment";

export default {
  name: "VoorinstelOrders",
  components: { DynamicGrid, ConfirmationModal, MeasurementsModal },
  props: {
    machineId: {
      required: false,
    },
    wissellijstSelectedRegels: {
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const {
      machineId: machineId,
      wissellijstSelectedRegels: wissellijstSelectedRegels,
    } = toRefs(props);
    if (!store.state.dynamicgrid["VVoorinstelRegels"]) {
      store.commit("dynamicgrid/SET_NEW_GRID", "VVoorinstelRegels");
      store.commit("dynamicgrid/SET_NEW_GRID", "VVoorinstelOrders");
      store.commit("dynamicgrid/SET_NEW_GRID", "VVoorinstelOrderRegels");

      store.dispatch("voorinstelorders/getVoorinstelRegelsMetadata");
      store.dispatch("voorinstelorders/getVoorinstelOrdersMetadata");
    }

    store.dispatch("voorinstelorders/getVoorinstelRegelsData");
    store.dispatch("voorinstelorders/getVoorinstelOrdersData");
    store.dispatch("voorinstelorders/getMachines");

    const selectedMachineId = computed(
      () => store.state.voorinstelorders.machineId
    );
    const voorinstelRegelsSelectedRows = computed(
      () => store.state.dynamicgrid["VVoorinstelRegels"].selectedRows
    );

    const voorinstelOrdersSelectedRow = computed(() => {
      if (
        store.state.dynamicgrid["VVoorinstelOrders"].selectedRows.length > 0
      ) {
        return store.state.dynamicgrid["VVoorinstelOrders"].selectedRows[0];
      }
      return undefined;
    });

    watch(voorinstelOrdersSelectedRow, () => {
      if (voorinstelOrdersSelectedRow.value) {
        const correspondingOrder = voorinstelOrdersData.value.filter(
          (x) => x.id == voorinstelOrdersSelectedRow.value.id
        )[0];
        store.commit(
          "voorinstelorders/SET_VOORINSTEL_ORDER_REGELS_DATA",
          correspondingOrder.regels
        );
      } else {
        store.commit("voorinstelorders/SET_VOORINSTEL_ORDER_REGELS_DATA", []);
      }
    });

    const voorinstelRegelsMetadata = computed(
      () => store.state.voorinstelorders.voorinstelRegelsMetadata
    );
    const voorinstelRegelsData = computed(
      () => store.state.voorinstelorders.voorinstelRegelsData
    );

    const voorinstelOrdersMetadata = computed(
      () => store.state.voorinstelorders.voorinstelOrdersMetadata
    );
    const voorinstelOrdersData = computed(
      () => store.state.voorinstelorders.voorinstelOrdersData
    );

    const voorinstelOrderRegelsData = computed(
      () => store.state.voorinstelorders.voorinstelOrderRegelsData
    );
    const voorinstelOrderRegelsMetadata = computed(
      () => store.state.voorinstelorders.voorinstelOrderRegelsMetadata
    );

    const selectedRegelsData = computed(
      () => store.state.voorinstelorders.selectedRegelsData
    );
    const selectedOrdersData = computed(
      () => store.state.voorinstelorders.selectedOrdersData
    );

    const machines = computed(() => store.state.voorinstelorders.machines);

    const voorinstelOrderRegelsGridRows = computed(
      () => store.state.dynamicgrid["VVoorinstelOrderRegels"].rows
    );

    const areAllQrTextFieldsFilledInForVoorinstelOrderRegels = computed(() => {
      return (
        voorinstelOrderRegelsGridRows.value.filter((x) => {
          return !x.qrText;
        }).length == 0
      );
    });

    const updateLocationsDisabled = computed(() => {
      const locations = voorinstelOrderRegelsGridRows.value
        .filter((x) => x.locatie != undefined)
        .map((x) => x.locatie);
      const unique = Array.from(new Set(locations));

      return locations.length !== unique.length;
    });

    const hasWriteRole = computed(
      () => store.state.user?.roles?.includes("voorinstelorders") ?? false
    );

    const isPrinterSelected = computed(() => {
      return !!store.state.selectedPrinter;
    });

    const modalBody = ref("");
    const deleteModalActive = ref(false);
    const measurementsToConfirm = computed(
      () => store.state.voorinstelorders.measurementsToConfirm
    );

    const isDeleteOrder = ref(false);

    const regelsGridParams = ref(undefined);
    const isWissellijstSelectionFinished = ref(false);
    function selectRegelsOnDataChange(params) {
      if (params) {
        regelsGridParams.value = params;
      }

      if (
        wissellijstSelectedRegels.value?.length &&
        machines.value?.length &&
        !isWissellijstSelectionFinished.value
      ) {
        setTimeout(() => setSelectedRowsFromWissellijstRegels(), 0);
      }
    }

    watch(
      [machines, voorinstelRegelsData],
      () => {
        if (machines.value?.length && voorinstelRegelsData.value?.length) {
          onMachineChange({
            target: {
              value: machineId.value ?? selectedMachineId.value,
            },
          });

          if (
            wissellijstSelectedRegels.value?.length &&
            regelsGridParams.value?.api &&
            !isWissellijstSelectionFinished.value
          ) {
            setTimeout(() => setSelectedRowsFromWissellijstRegels(), 0);
          }
        }
      },
      { immediate: true }
    );

    const voorinstelOrderApi = ref(undefined);
    function onVoorinstelOrdersDataChanged(params) {
      voorinstelOrderApi.value = params;
    }

    function getRegelsModalBody() {
      let mainMessage = `Weet u zeker dat u de ${voorinstelRegelsSelectedRows.value.length} geselecteerde regels wilt verwijderen?\r\n`;

      const fromMagazijnen = [];
      voorinstelRegelsSelectedRows.value.forEach((row) => {
        if (row.machineMagazijnId) {
          fromMagazijnen.push(row);
        }
      });

      if (fromMagazijnen.length > 0) {
        mainMessage +=
          "\r\nDe regels voor de volgende tools worden geannuleerd en de bijbehorende magazijnen worden op niet versleten gezet:\r\n";
        mainMessage += fromMagazijnen
          .map((t) => t.werkelijkeTool.unieknummer)
          .join(",\r\n");
      }

      return mainMessage;
    }

    function exportPdf() {
      DownloadClient()
        .get(
          `voorinstelorders/download/${voorinstelOrdersSelectedRow.value.id}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Voorinstelorder ${moment(
              voorinstelOrdersSelectedRow.value.datum
            ).format("DD-MM-YYYY")}`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit(
            "ADD_TOAST_SUCCESS",
            "De voorinstelorder is opgeslagen als pdf.",
            { root: true }
          );
        })
        .catch(() =>
          store.commit(
            "ADD_TOAST_ERROR",
            "De voorinstelorder kon niet worden geëxporteerd.",
            { root: true }
          )
        );
    }

    function getModalBodyText() {
      let mainMessage =
        "Weet u zeker dat u de geselecteerde voorinstelorder wilt verwijderen?\r\n";
      if (voorinstelOrdersSelectedRow.value.status <= 1) {
        return mainMessage;
      }

      const toTopGrid = [];
      const fromMagazijnen = [];

      voorinstelOrderRegelsGridRows.value.forEach((row) => {
        if (row.machineMagazijnId) {
          fromMagazijnen.push(row);
        } else if (!row.repair) {
          toTopGrid.push(row);
        }
      });

      if (fromMagazijnen.length > 0) {
        mainMessage +=
          "\r\nDe regels voor de volgende tools worden geannuleerd en de bijbehorende magazijnen worden op niet versleten gezet:\r\n";
        mainMessage += fromMagazijnen
          .map((t) => t.werkelijkeTool.unieknummer)
          .join(",\r\n");
      }

      if (toTopGrid.length > 0) {
        mainMessage +=
          "\r\nDe regels voor de volgende tools worden losgekoppeld van de order: \r\n";
        mainMessage += toTopGrid
          .map((t) => t.werkelijkeTool.unieknummer)
          .join(",\r\n");
      }

      return mainMessage;
    }
    function onMachineChange(event) {
      store.commit(
        "voorinstelorders/SET_SELECTED_MACHINE_ID",
        event.target.value
      );
      store.commit(
        "voorinstelorders/SET_SELECTED_REGELS_DATA",
        voorinstelRegelsData.value.filter(
          (regel) =>
            regel?.machineId == event.target.value && !regel?.voorinstelOrderId
        )
      );
      store.commit(
        "voorinstelorders/SET_SELECTED_ORDERS_DATA",
        voorinstelOrdersData.value.filter(
          (order) => order?.machineId == event.target.value
        )
      );
      store.commit("voorinstelorders/SET_VOORINSTEL_ORDER_REGELS_DATA", []);
      store.commit("dynamicgrid/SET_SELECTED_ROWS", {
        payload: [],
        identifier: "VVoorinstelOrders",
      });
    }

    function setSelectedRowsFromWissellijstRegels() {
      regelsGridParams.value.api.forEachNode((node) => {
        wissellijstSelectedRegels.value.forEach((regel) => {
          if (regel == node.data.id) {
            node.setSelected(true);
            // This won't affect the next nodes to be selected. This only ensures that this method is not called again.
            // It avoids that changes to the selection are overridden when you change the machine again.
            isWissellijstSelectionFinished.value = true;
          }
        });
      });
    }

    function onCreateOrder() {
      store.dispatch(
        "voorinstelorders/createOrder",
        voorinstelRegelsSelectedRows.value
      );
    }

    function onDelete() {
      if (isDeleteOrder.value) {
        store.dispatch("voorinstelorders/deleteOrder", {
          orderId: voorinstelOrdersSelectedRow.value.id,
          status: voorinstelOrdersSelectedRow.value.status,
        });
      } else {
        store.dispatch(
          "voorinstelorders/deleteRegels",
          voorinstelRegelsSelectedRows.value.map((r) => r.id)
        );
      }

      deleteModalActive.value = false;
    }

    function callback() {
      setTimeout(() => {
        voorinstelOrderApi.value.api.forEachNode((node) => {
          if (voorinstelOrdersSelectedRow.value.id == node.data.id)
            node.setSelected(
              voorinstelOrdersSelectedRow.value.id == node.data.id
            );
        });
      }, 0);
    }
    function changeStatus(status, ignoreTolerances = false) {
      store.dispatch("voorinstelorders/updateOrder", {
        id: voorinstelOrdersSelectedRow.value.id,
        order: voorinstelOrdersSelectedRow.value,
        regels: voorinstelOrderRegelsGridRows.value,
        status,
        callback,
        ignoreTolerances,
      });
    }

    function updateQrCodesAndLocations() {
      voorinstelOrderRegelsGridRows.value.forEach((r) => {
        r.locatie =
          r.locatie == -1 || r.locatie == "Uw Keuze" ? undefined : r.locatie;
      });
      store.dispatch("voorinstelorders/updateQrCodesAndLocations", {
        rows: voorinstelOrderRegelsGridRows.value,
        orderId: voorinstelOrdersSelectedRow.value.id,
      });
    }

    function cancelMeasurements() {
      store.commit("voorinstelorders/SET_MEASUREMENTS_TO_CONFIRM", []);
    }

    function confirmMeasurements() {
      changeStatus(3, true);
    }

    function printLabels(allLabels=false) {
      store.dispatch('voorinstelorders/printLabels', allLabels===true ? voorinstelOrderRegelsGridRows.value : voorinstelOrderRegelsGridRows.value.filter(x=>x.print));
    }

    return {
      voorinstelRegelsMetadata,
      voorinstelRegelsData,
      voorinstelRegelsSelectedRows,
      voorinstelOrdersMetadata,
      voorinstelOrdersData,
      voorinstelOrderRegelsData,
      voorinstelOrderRegelsMetadata,
      voorinstelOrdersSelectedRow,
      voorinstelOrderRegelsGridRows,
      onCreateOrder,
      onMachineChange,
      machines,
      selectedRegelsData,
      selectedOrdersData,
      modalBody,
      onDelete,
      deleteModalActive,
      isDeleteOrder,
      selectRegelsOnDataChange,
      selectedMachineId,
      changeStatus,
      store,
      areAllQrTextFieldsFilledInForVoorinstelOrderRegels,
      updateLocationsDisabled,
      onVoorinstelOrdersDataChanged,
      updateQrCodesAndLocations,
      getModalBodyText,
      getRegelsModalBody,
      hasWriteRole,
      measurementsToConfirm,
      cancelMeasurements,
      confirmMeasurements,
      exportPdf,
      isPrinterSelected,
      printLabels
    };
  },
};
</script>
