<template>
  <CCard class="col-md-8">
    <CCardHeader>
      <strong>Component</strong>
      <CButton
        @click="goToGrid"
        class="btn btn-primary btn-sm"
        style="float: right"
        >Annuleren</CButton
      >
      <CButton
        :disabled="validationErrors.length"
        @click="onSubmit"
        class="btn btn-primary btn-sm"
        style="float: right; margin-right: 4px"
        >Opslaan</CButton
      >
    </CCardHeader>
    <CCardBody>
      <dynamic-form
        :metadata="metadata"
        :data="data"
        :name="formIdentifier"
        :isEdit="!!id"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import DynamicForm from "../dynamicform/DynamicForm.vue";
import CrudService from "../../services/CrudService";
import { useRouter } from "vue-router";

export default {
  name: "ComponentsCrud",
  components: { DynamicForm },
  props: {
    id: {
      required: false,
    },
  },
  setup(props) {
    const id = ref(props.id);
    const store = useStore();
    const router = useRouter();

    const mainIdentifier = "components" + Math.floor(Math.random() * 1000000);
    const formIdentifier = "form" + Math.floor(Math.random() * 1000000);

    store.commit("componentscrud/SET_NEW_COMPONENTS_FORM", mainIdentifier);
    store.commit("dynamicform/SET_NEW_FORM", formIdentifier);
    store.dispatch("componentscrud/getMetadata", mainIdentifier);
    if (id?.value) {
      store.dispatch("componentscrud/getFieldValues", {
        id: id.value,
        identifier: mainIdentifier,
      });
    }

    const metadata = computed(
      () => store.state.componentscrud[mainIdentifier].metadata
    );
    const data = computed(
      () => store.state.componentscrud[mainIdentifier].fieldValues
    );
    const loadingStatus = computed(
      () => store.state.dynamicform[formIdentifier].loadingStatus
    );
    const validationErrors = computed(
      () => store.state.dynamicform[formIdentifier].validationErrors
    );

    const lastPage = computed(() => store.state.lastPage);

    const onSubmit = () => {
      store.commit("dynamicform/SET_LOADING_STATUS", {
        loadingStatus: true,
        identifier: formIdentifier,
      });
      const isUpdate = !!id.value;
      const body = store.state.dynamicform[formIdentifier].fieldValues;

      const updateOrAdd = () =>
        isUpdate
          ? CrudService.putData("components", id.value, data.value)
          : CrudService.postData("components", body);
      updateOrAdd()
        .then(async (response) => {
          if (response?.data?.success === false) {
            const errorMsg = response?.data?.messages?.filter(
              (m) => m.messageType == 2
            )[0].text;
            store.commit(
              "ADD_TOAST_ERROR",
              errorMsg ?? "Uw wijzigingen konden niet worden opgeslagen.",
              { root: true }
            );
            return;
          } else if (isUpdate) {
            store.commit(
              "ADD_TOAST_SUCCESS",
              "Uw wijzigingen zijn opgeslagen.",
              { root: true }
            );
          } else {
            store.commit(
              "ADD_TOAST_SUCCESS",
              "Uw tool is succesvol toegevoegd.",
              { root: true }
            );
            id.value = response.data.id;
          }
          goToGrid();
        })
        .catch((err) => store.commit("ADD_TOAST_ERROR", err, { root: true }))
        .finally(() =>
          store.commit("dynamicform/SET_LOADING_STATUS", {
            loadingStatus: false,
            identifier: formIdentifier,
          })
        );
    };

    function goToGrid() {
      router.push(
        lastPage.value ?? {
          name: "components",
          params: {},
        }
      );
    }

    return {
      metadata,
      data,
      loadingStatus,
      validationErrors,
      onSubmit,
      goToGrid,
      formIdentifier,
    };
  },
};
</script>
