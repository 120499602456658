<template>
  <CCard class="col-md-8">
    <CCardHeader>
      <strong>Werkelijke tool</strong>
      <CButton
        @click="goToToolsCrud(false)"
        class="btn btn-primary btn-sm"
        style="float: right"
        >Annuleren</CButton
      >
      <CButton
        :disabled="validationErrors.length"
        @click="goToToolsCrud(true)"
        class="btn btn-primary btn-sm"
        style="float: right; margin-right: 4px"
        >Opslaan</CButton
      >
    </CCardHeader>
    <CCardBody>
      <dynamic-form
        :metadata="metadata"
        :data="data"
        name="TWTForm"
        :isEdit="isEdit"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, toRefs, watch } from "vue";
import DynamicForm from "../dynamicform/DynamicForm.vue";
import { useRouter } from "vue-router";
import CrudService from "../../services/CrudService";

export default {
  name: "WerkelijkeToolsCrud",
  components: { DynamicForm },
  props: {
    isEdit: {
      required: true,
    },
    toolId: {
      required: false,
    },
    overnemenXWaarde: {
      required: true
    }
  },
  setup(props) {
    const { isEdit: isEdit, toolId: toolId, overnemenXWaarde:overnemenXWaarde } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const werkelijkeToolsIdentifier = "werkelijkeToolsInTool";
    store.commit("dynamicform/SET_NEW_FORM", "TWTForm");
    store.dispatch("toolscrud/getWerkelijkeToolsFormMetadata", {
      id:
        isEdit.value === true
          ? store.state.dynamicgrid[werkelijkeToolsIdentifier].selectedRows[0]
              .id
          : undefined,
      machineGroep: store.state.toolscrud.toolsData.machineGroep,
    });

    const metadata = computed(
      () => store.state.toolscrud.werkelijkeToolsFormMetadata
    );
    const data = ref({});
    const validationErrors = computed(
      () => store.state.dynamicform["TWTForm"].validationErrors
    );

    watch(
      isEdit,
      () => {
        if (isEdit.value == "true") {
          data.value =
            store.state.dynamicgrid[werkelijkeToolsIdentifier].selectedRows[0];
          if (!data.value.locatie?.id) data.value.locatie = undefined;
        }
        data.value.toolId = parseInt(toolId.value);
        data.value.tool = { overnemenXWaarde: overnemenXWaarde.value == 'true' || overnemenXWaarde.value === true };
      },
      { immediate: true }
    );

    function goToToolsCrud(isSubmit) {
      if (!isSubmit) {
        router.push({
          name: "toolscrud",
          params: {
            id: toolId.value,
          },
        });
      } else {
        saveTool();
      }
    }

    function saveTool() {
      data.value.tool = undefined;
      const isUpdate = isEdit.value == "true";
      data.value.machine = undefined;
      data.value.tool = undefined;
      data.value.locatie = undefined;

      const method = isUpdate
        ? CrudService.putData("werkelijketools", data.value.id, data.value)
        : CrudService.postData("werkelijketools", data.value);

      method
        .then((response) => {
          if (!response.data.success) {
            store.commit(
              "HANDLE_RESPONSE",
              { response: response.data },
              { root: true }
            );
            return;
          }
          if (isUpdate) {
            store.commit(
              "ADD_TOAST_SUCCESS",
              "Uw werkelijke tool is opgeslagen.",
              { root: true }
            );
            let index =
              store.state.toolscrud.toolsData.werkelijkeTools.findIndex(
                (w) => w.id == data.value.id
              );
            store.state.toolscrud.toolsData.werkelijkeTools[index] =
              response.data.value;
          } else {
            store.commit(
              "ADD_TOAST_SUCCESS",
              "Uw werkelijke tool is succesvol toegevoegd.",
              { root: true }
            );
            store.state.toolscrud.toolsData.werkelijkeTools.push(
              response.data.value
            );
          }

          router.push({
            name: "toolscrud",
            params: {
              id: toolId.value,
            },
          });
        })
        .catch((err) => store.commit("ADD_TOAST_ERROR", err, { root: true }));
    }

    return {
      metadata,
      data,
      validationErrors,
      goToToolsCrud,
    };
  },
};
</script>