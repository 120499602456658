import axios from "axios";
import store from "../store";

export default () =>
  axios.create({
    baseURL: store.state.baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.user.accessToken}`,
    },
  });
