<template>
  <CAlert
    v-if="warnings?.length"
    color="warning"
    dismissible
    @close="clearWarning"
  >
    <CLabel v-for="e in warnings" :key="e">{{ e }}<br /></CLabel>
  </CAlert>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "WarningAlert",
  props: {},
  setup() {
    const store = useStore();
    const warnings = computed(() => {
      if (!store.state.warning) return [];

      if (!store.state.warning.message) {
        return store.state.warning.split("\n");
      }
      return [store.state.warning.message];
    });

    function clearWarning() {
      store.commit("CLEAR_WARNING", { root: true });
    }
    return {
      warnings,
      clearWarning,
    };
  },
};
</script>
