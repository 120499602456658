<template>
  <CCard class="col-md-8">
    <CCardHeader>
      <strong>Opspanning</strong>
      <CButton
        @click="goToOrdersCrud(false)"
        class="btn btn-primary btn-sm"
        style="float: right"
        >Annuleren</CButton
      >
      <CButton
        :disabled="validationErrors.length"
        @click="goToOrdersCrud(true)"
        class="btn btn-primary btn-sm"
        style="float: right; margin-right: 4px"
        >Opslaan</CButton
      >
    </CCardHeader>
    <CCardBody>
      <dynamic-form
        :metadata="metadata"
        :data="data"
        name="OOForm"
        :isEdit="isEdit"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, toRefs, watch } from "vue";
import DynamicForm from "../dynamicform/DynamicForm.vue";
import { useRouter } from "vue-router";
import CrudService from "../../services/CrudService";

export default {
  name: "OpspanningenCrud",
  components: { DynamicForm },
  props: {
    isEdit: {
      required: true,
    },
    identifier: {
      required: true,
    },
    orderId: {
      required: true,
    },
  },
  setup(props) {
    const {
      isEdit: isEdit,
      identifier: identifier,
      orderId: orderId,
    } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    const ordersIdentifier = "orders" + identifier.value;
    const opspanningenIdentifier = "opspanningen" + identifier.value;

    store.commit("dynamicform/SET_NEW_FORM", "OOForm");
    store.dispatch("orderscrud/getOpspanningenFormMetadata", isEdit.value);

    const metadata = computed(
      () => store.state.orderscrud.opspanningenFormMetadata
    );
    const data = ref({});
    const validationErrors = computed(
      () => store.state.dynamicform["OOForm"].validationErrors
    );

    watch(
      isEdit,
      () => {
        if (isEdit.value == "true") {
          data.value =
            store.state.dynamicgrid[opspanningenIdentifier].selectedRows[0];
        }
        data.value.orderId = Number(orderId.value);
      },
      { immediate: true }
    );

    function goToOrdersCrud(isSubmit) {
      store.state.dynamicgrid[opspanningenIdentifier].selectedRows = [];
      if (!isSubmit) {
        router.push({
          name: "orderscrud",
          params: {
            id: orderId.value,
            identifier: identifier.value,
          },
        });
      } else {
        saveOrder();
      }
    }

    function saveOrder() {
      const isUpdate = isEdit.value == "true";

      const method = isUpdate
        ? CrudService.putData("opspanningen", data.value.id, data.value)
        : CrudService.postData("opspanningen", data.value);

      method
        .then((response) => {
          if (!response.data.success) {
            store.commit(
              "HANDLE_RESPONSE",
              { response: response.data },
              { root: true }
            );
            return;
          }
          if (isUpdate) {
            store.commit("ADD_TOAST_SUCCESS", "Uw opspanning is opgeslagen.", {
              root: true,
            });
            let index = store.state.orderscrud[
              ordersIdentifier
            ].ordersData.opspanningen.findIndex((o) => o.id == data.value.id);
            store.state.orderscrud[ordersIdentifier].ordersData.opspanningen[
              index
            ] = response.data.value;
          } else {
            store.commit(
              "ADD_TOAST_SUCCESS",
              "Uw opspanning is succesvol toegevoegd.",
              { root: true }
            );
            store.state.orderscrud[
              ordersIdentifier
            ].ordersData.opspanningen.push(response.data.value);
          }

          router.push({
            name: "orderscrud",
            params: {
              id: orderId.value,
              identifier: identifier.value,
            },
          });
        })
        .catch((err) => store.commit("ADD_TOAST_ERROR", err, { root: true }))
        .finally(() =>
          store.commit("dynamicform/SET_LOADING_STATUS", {
            loadingStatus: false,
            identifier: ordersIdentifier,
          })
        );
    }

    return {
      metadata,
      data,
      validationErrors,
      goToOrdersCrud,
    };
  },
};
</script>
