<template>
  <CModal size="xl">
    <CModalHeader>
      <a
        @click.stop.prevent="
          () => {
            imageIsActive = false;
          }
        "
        v-if="imageIsActive"
      >
        <CIcon class="text-secondary" :icon="cilArrowLeft" size="xl" />
      </a>
      <CModalTitle class="ms-3">{{
        imageIsActive ? "Afbeelding" : metadata.modelDisplayName
      }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="imageIsActive">
      <CImage
        rounded
        class="img-fluid border border-light"
        style="object-fit: contain"
        :src="image"
      />
    </CModalBody>
    <CModalBody v-if="!imageIsActive">
      <CRow class="mb-3">
        <CCol :xs="12" :lg="5">
          <a class="position-relative">
            <CImage
              @click.stop.prevent="displayImage()"
              style="object-fit: contain"
              v-if="image"
              rounded
              class="border border-light"
              height="300"
              width="300"
              :src="image"
            />
          </a>
        </CCol>
        <CCol :xs="12" :lg="7">
          <CRow
            v-for="property in metadata.properties?.filter(
              (p) => p.displayType != 5
            )"
            :key="property"
            class="mb-2"
          >
            <CCol> {{ property.displayName }} </CCol>
            <CCol>
              {{ modalData[property.variableName] }}
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script>
import { defineComponent, toRefs, ref, watch } from "vue";
import { useStore } from "vuex";
import { getDisplayType } from "@/helpers/gridHelper.js";
import { createUrl } from "@/helpers/apiHelper.js";
import DownloadClient from "@/services/DownloadClient";
import moment from "moment";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";

export default defineComponent({
  name: "DynamicModal",
  components: {
    CIcon,
  },
  props: {
    name: {
      required: true,
    },
    data: {
      required: true,
    },
    metadata: {
      required: true,
    },
  },
  setup(props) {
    const { name: name, data: data, metadata: metadata } = toRefs(props);

    const store = useStore();

    const image = ref(null);
    const imageIsActive = ref(false);

    const modalData = ref({});

    store.commit("dynamicmodal/SET_MODAL_METADATA", {
      payload: metadata.value,
      identifier: name.value,
    });
    store.commit("dynamicmodal/SET_MODAL_VALUES", {
      payload: data.value,
      identifier: name.value,
    });

    watch(
      [metadata, data],
      () => {
        if (
          metadata.value?.properties?.length &&
          data.value &&
          Object.keys(data.value).length !== 0
        ) {
          modalData.value = JSON.parse(JSON.stringify(data.value));
          metadata.value.properties.forEach((property) => {
            const type = getDisplayType(property);
            switch (type) {
              case "file":
                DownloadClient()
                  .get(
                    createUrl(
                      property.downloadConfiguration,
                      modalData.value.file,
                      modalData.value.id
                    ),
                    {
                      responseType: "arraybuffer",
                    }
                  )
                  .then((response) => {
                    image.value =
                      "data:image/.jpeg;base64," +
                      Buffer.from(response.data, "binary").toString("base64");
                  })
                  .catch((error) => {
                    throw error;
                  });
                break;
              case "date":
                modalData.value[property.variableName] = moment(
                  modalData.value[property.variableName]
                ).format("DD-MM-YYYY");
                break;
              case "datetime-local":
                modalData.value[property.variableName] = moment(
                  modalData.value[property.variableName]
                ).format("DD-MM-YYYY HH:mm");
                break;
              case "checkbox":
                modalData.value[property.variableName] =
                  modalData.value[property.variableName] == true ? "Ja" : "Nee";
                break;
            }
          });
        }
      },
      { immediate: true }
    );

    // function getParentProperty(prop, row) {
    //   if (!prop.displayPropertiesPath?.length) return row[prop.variableName];
    //   let lastProperty = undefined;
    //   for (let path of prop.displayPropertiesPath) {
    //     path = path[0].toLowerCase() + path.slice(1);
    //     lastProperty = lastProperty ? lastProperty[path] : row[path];
    //   }

    //   return prop.takeFirst && lastProperty?.length ? lastProperty[0] : lastProperty;
    // }

    function displayImage() {
      imageIsActive.value = true;
    }
    return {
      displayImage,
      imageIsActive,
      image,
      modalData,
      cilArrowLeft,
    };
  },
});
</script>
