<template>
  <CAlert v-if="errors?.length" color="danger" dismissible @close="clearError">
    <CLabel v-for="e in errors" :key="e">{{ e }}<br /></CLabel>
  </CAlert>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "ErrorAlert",
  props: {},
  setup() {
    const store = useStore();
    const errors = computed(() => {
      if (!store.state.error) return [];

      if (!store.state.error.message) {
        return store.state.error.split("\n");
      }
      return [store.state.error.message];
    });

    function clearError() {
      store.commit("CLEAR_ERROR", { root: true });
    }
    return {
      errors,
      clearError,
    };
  },
};
</script>
