<template>
  <select
    class="form-select form-select-sm"
    v-if="!autoPage && !tooFewRows"
    @change="onPageSizeChanged"
    v-model="pageSizeRef"
    size="sm"
    style="width: 90px; float: right; margin-right: 10px"
  >
    <option disabled>Aantal Rijen</option>
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="30">30</option>
    <option value="40">40</option>
  </select>
  <ag-grid-vue
    :id="componentName"
    :class="
      heightOfTable != undefined
        ? 'ag-theme-balham ag-theme-balham-tablet'
        : 'ag-theme-balham'
    "
    :style="{
      width: '100%',
      height: `${
        heightOfTable ?? (autoPage || tooFewRows ? '300px' : undefined)
      }`,
      minHeight: `${heightOfTable ?? (tooFewRows ? '100%' : undefined)}`,
    }"
    :columnDefs="columns"
    :rowData="rows"
    @first-data-rendered="autoResizeColumns"
    @model-updated="autoResizeColumns"
    :rowSelection="multipleSelection ? 'multiple' : 'single'"
    :rowMultiSelectWithClick="multipleSelection && !isSelectAll"
    :rowClassRules="rowRules"
    @filter-changed="onFilterChanged"
    @sort-changed="onSortChanged"
    @pagination-changed="onPaginationChanged"
    showLoadingOverlay="false"
    @selection-changed="onSelectionChanged"
    @grid-ready="onGridReady"
    @row-double-clicked="onRowDoubleClicked"
    :paginationAutoPageSize="autoPage || tooFewRows"
    :pagination="true"
    :localeText="localeText"
    :frameworkComponents="components"
    :rowHeight="heightOfRows"
    suppressPropertyNamesCheck="true"
    :suppressDragLeaveHidesColumns="true"
    :isRowSelectable="isRowSelectable"
    :suppressRowTransform="true"
    :suppressRowClickSelection="multipleSelection && isSelectAll"
  />
</template>

<script>
import { computed, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { AG_GRID_LOCALE_NL } from "@/localisation/localenl.js";
import CheckboxCellRenderer from "@/components/CheckboxCellRenderer.vue";
import DropdownCellRenderer from "@/components/DropdownCellRenderer.vue";

export default {
  name: "DynamicGrid",
  props: {
    name: {
      required: true,
    },
    data: {
      required: true,
    },
    metadata: {
      required: true,
    },
    autoPagination: {
      required: true,
    },
    maxColumnsBeforeOverflow: {
      required: false,
    },
    multiSelect: {
      required: false,
    },
    rowClassRules: {
      required: false,
    },
    heightOfRows: {
      required: false,
    },
    heightOfTable: {
      required: false,
    },
    tabletView: {
      required: false,
    },
  },
  emits: ["dataChanged", "doubleClicked"],
  setup(props, { emit }) {
    const {
      name: componentName,
      autoPagination: autoPage,
      metadata: metadata,
      data: data,
      maxColumnsBeforeOverflow: maxColumns,
      multiSelect: multipleSelection,
      rowClassRules: rowRules,
      tabletView: tabletView,
    } = toRefs(props);

    const store = useStore();
    const localeText = AG_GRID_LOCALE_NL;
    const gridApi = ref(null);
    const columnApi = ref(null);
    const pageSizeRef = ref(10);

    const columns = computed(
      () => store.state.dynamicgrid[componentName.value].columns
    );
    const rows = computed(
      () => store.state.dynamicgrid[componentName.value].rows
    );
    const tooFewRows = computed(() => {
      const length = store.state.dynamicgrid[componentName.value].rows?.length;
      if (length === undefined) {
        return false;
      }
      return length < 10;
    });
    const components = {
      checkboxRenderer: CheckboxCellRenderer,
      dropdownCellRenderer: DropdownCellRenderer,
    };
    const filterModel = computed(
      () => store.state.dynamicgrid[componentName.value].filterModel
    );
    const sortModel = computed(
      () => store.state.dynamicgrid[componentName.value].sortModel
    );
    const pageNumber = computed(
      () => store.state.dynamicgrid[componentName.value].pageNumber
    );
    const pageSize = computed(
      () => store.state.dynamicgrid[componentName.value].pageSize
    );
    const isSelectAll = computed(
      () => metadata.value?.properties?.some((x) => x.selectAll) ?? false
    );

    watch(pageSizeRef, () => {
      store.commit("dynamicgrid/SET_PAGE_SIZE", {
        payload: pageSizeRef.value,
        identifier: componentName.value,
      });
    });

    watch(
      [metadata, data],
      () => {
        pageSizeRef.value = pageSize.value;
        // In case rows do not yet exist, you can fill metadata
        if (
          !data.value?.length &&
          metadata.value?.properties &&
          !columns.value?.length
        ) {
          store.dispatch("dynamicgrid/getColumns", {
            component: metadata.value,
            identifier: componentName.value,
            lockColumns: tabletView.value,
          });
          store.commit("dynamicgrid/SET_METADATA", {
            payload: metadata.value,
            identifier: componentName.value,
          });
          store.commit("dynamicgrid/SET_ROWS", {
            payload: [],
            identifier: componentName.value,
          });
        }
        // But if rows exist, metadata must exist - otherwise wait until it does
        else if (metadata.value?.properties && data.value?.length) {
          // In case both arrive at the same time, check if columns already exists. If not, populate it
          if (!columns.value?.length) {
            store.dispatch("dynamicgrid/getColumns", {
              component: metadata.value,
              identifier: componentName.value,
              lockColumns: tabletView.value,
            });
            store.commit("dynamicgrid/SET_METADATA", {
              payload: metadata.value,
              identifier: componentName.value,
            });
          }

          // Now we are sure that metadata exists, we can use the metadata to manipulate the displayed data
          // We do make a new list so the watch is not triggered again - delone
          const preparedRows = JSON.parse(JSON.stringify(data.value));
          metadata.value?.properties.forEach((prop) => {
            const variable =
              prop.variableName[0].toLowerCase() + prop.variableName.slice(1);
            preparedRows.forEach((row) => {
              if (prop.displayProperties?.length) {
                let parent = getParentProperty(prop, row) ?? {};
                const preparedArr = [];

                prop.displayProperties.forEach((displayProp) => {
                  const split = displayProp.split(".");
                  let actualPath = parent;
                  split.forEach((el) => {
                    actualPath = actualPath[el];
                    if (actualPath === undefined || actualPath === null) return;
                  });
                  preparedArr.push(actualPath);
                });
                row[variable] = preparedArr.join("-");
                if (prop.displayType == 1) {
                  row[variable] = Number(row[variable]);
                }
                if (prop.displayType == 3) {
                  row[variable] =
                    row[variable] === "true" ||
                    row[variable] === "True" ||
                    row[variable] === true;
                }
              } else if (prop.takeFirst && row[variable]?.length) {
                row[variable] = row[variable][0];
              }
            });
          });

          store.commit("dynamicgrid/SET_ROWS", {
            payload: preparedRows,
            identifier: componentName.value,
          });
        } else if (
          !data.value?.length &&
          metadata.value?.properties &&
          columns.value?.length
        ) {
          store.commit("dynamicgrid/SET_ROWS", {
            payload: [],
            identifier: componentName.value,
          });
        }
      },
      { immediate: true, deep: true }
    );

    function getParentProperty(prop, row) {
      if (!prop.displayPropertiesPath?.length) return row[prop.variableName];
      let lastProperty = undefined;
      for (let path of prop.displayPropertiesPath) {
        path = path[0].toLowerCase() + path.slice(1);
        lastProperty = lastProperty ? lastProperty[path] : row[path];
      }

      return prop.takeFirst && lastProperty?.length
        ? lastProperty[0]
        : lastProperty;
    }

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value ??= params.columnApi;

      document.addEventListener("keydown", (event) => {
        if (event.code === "KeyC" && (event.ctrlKey || event.metaKey)) {
          const cell = gridApi.value.getFocusedCell();

          if (cell) {
            const row = gridApi.value.getDisplayedRowAtIndex(cell.rowIndex);
            const value = gridApi.value.getValue(cell.column, row);
            navigator.clipboard.writeText(value);
          }
        }
      });

      // Once in a blue moon, the gridApi is undefined and causes a crash.
      if (!gridApi.value) return;

      if (filterModel.value) {
        gridApi.value.setFilterModel(filterModel.value);
      }

      if (sortModel.value) {
        columnApi.value.applyColumnState({ state: sortModel.value });
      }

      if (pageNumber.value !== undefined) {
        setTimeout(() => {
          gridApi.value.paginationGoToPage(pageNumber.value);
        }, 300);
      }

      if (!filterModel.value && !sortModel.value && !pageNumber.value) {
        columnApi.value.applyColumnState({ state: getInitialSortModel() });
      }

      if (!autoPage.value && !tooFewRows.value) {
        onPageSizeChanged();
      } else {
        gridApi.value.setDomLayout("normal");
      }
      // This seems to cause issues in a new version of Ag-Grid. I don't remember the purpose of refreshing the cells.
      // params.api.refreshCells();
    };

    function getInitialSortModel() {
      const sortModel = [];

      if (metadata.value?.properties) {
        metadata.value.properties.forEach((p) => {
          if (p.defaultSort) {
            sortModel.push({
              colId: p.variableName,
              sort: p.defaultSort,
            });
          }
        });
      }

      return sortModel;
    }

    function getRowNodeId(data) {
      return data.id;
    }

    watch(
      rows,
      () => {
        if (rows.value?.length) {
          onGridReady({ api: gridApi.value, columnApi: columnApi.value });
        }
      },
      { immediate: true, deep: true }
    );

    function onSelectionChanged(params) {
      const rows = params.api.getSelectedRows();
      if (rows.length == 1) {
        const index = params.api.rowModel.rowsToDisplay.filter(
          (x) => x.selected
        )[0].rowIndex;
        const pageSize = params.api.paginationGetPageSize();
        const pageToNavigateTo = Math.floor(index / pageSize);

        if (pageToNavigateTo != params.api.paginationGetCurrentPage())
          params.api.paginationGoToPage(pageToNavigateTo);
      }
      store.commit("dynamicgrid/SET_SELECTED_ROWS", {
        payload: rows ?? [],
        identifier: componentName.value,
      });
    }

    function autoResizeColumns(params) {
      if (columns.value.length < (maxColumns.value ?? 10)) {
        params.api.sizeColumnsToFit();
      }
      emit("dataChanged", params);
    }

    function onPageSizeChanged() {
      gridApi.value.paginationSetPageSize(Number(pageSizeRef.value));
      gridApi.value.setDomLayout("autoHeight");
      document.querySelector("#" + componentName.value).style.height = "";
      autoResizeColumns({ api: gridApi.value });
    }

    function onFilterChanged() {
      store.commit("dynamicgrid/SET_FILTER_MODEL", {
        payload: gridApi.value.getFilterModel(),
        identifier: componentName.value,
      });
      store.commit("dynamicgrid/SET_SELECTED_ROWS", {
        payload: [],
        identifier: componentName.value,
      });
      gridApi.value.deselectAll();
    }

    function onSortChanged() {
      const sort = columnApi.value?.getColumnState();
      if (sort) {
        store.commit("dynamicgrid/SET_SORT_MODEL", {
          payload: sort,
          identifier: componentName.value,
        });
      }
    }

    function onPaginationChanged(event) {
      if (event.newPage) {
        const page = gridApi.value?.paginationGetCurrentPage();
        store.commit("dynamicgrid/SET_PAGE_NUMBER", {
          payload: page,
          identifier: componentName.value,
        });
      }
    }

    function onRowDoubleClicked(row) {
      emit("doubleClicked", row.data);
    }

    const isRowSelectable = computed(() => {
      const result = columns.value.filter((c) => c.isRowSelectable)[0]
        ?.isRowSelectable;
      return result;
    });

    return {
      isRowSelectable,
      onSelectionChanged,
      columns,
      rows,
      autoResizeColumns,
      localeText,
      onPageSizeChanged,
      autoPage,
      onGridReady,
      componentName,
      tooFewRows,
      multipleSelection,
      rowRules,
      components,
      onFilterChanged,
      onRowDoubleClicked,
      onPaginationChanged,
      onSortChanged,
      sortModel,
      pageNumber,
      pageSizeRef,
      getRowNodeId,
      isSelectAll,
    };
  },
};
</script>
