import CrudService from "../../services/CrudService";
import moment from "moment";
import { loadOptionsAsync } from "@/helpers/apiHelper.js";

const namespaced = true;

const state = {};

const mutations = {
  SET_FIELD_VALUES(state, { payload, identifier }) {
    state[identifier].fieldValues = payload;
  },

  SET_METADATA(state, { payload, identifier }) {
    state[identifier].metadata = payload;
  },

  SET_NEW_FORM_VIEW(state, identifier) {
    state[identifier] = {
      fieldValues: {},
      metadata: undefined,
      validationErrors: [],
      submitLoadingStatus: false,
    };
  },
};

const actions = {
  getMetadata({ commit }, { id, endpoint, identifier }) {
    CrudService.getMetadataByName(endpoint, "form")
      .then(async (response) => {
        // Filter out the ones that are not editable only if it is add
        response.data.properties = response.data.properties.filter(
          (prop) => !!id || prop.editable
        );
        await loadOptionsAsync(
          response.data.properties,
          response.data.endpoint,
          id
        );
        commit("SET_METADATA", { payload: response.data, identifier });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getFieldValues({ commit }, { endpoint, id, identifier }) {
    CrudService.getDataById(endpoint, id)
      .then((response) => {
        if (response.data.success) {
          for (let property in response.data.value) {
            if (
              moment(
                response.data.value[property],
                "YYYY-MM-DDTHH:mm:ss",
                true
              ).isValid() === true
            ) {
              response.data.value[property] =
                response.data.value[property].split("T")[0];
            }
          }
          commit("SET_FIELD_VALUES", {
            payload: response.data.value,
            identifier,
          });
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const dynamicformview = {
  state,
  mutations,
  actions,
  namespaced,
};

export default dynamicformview;
