import CrudService from "@/services/CrudService";
import { loadOptionsAsync } from "@/helpers/apiHelper.js";

export default {
  namespaced: true,
  state: {
    data: { machineMagazijnen: [] },
    metadata: {},
    magazijnenMetadata: {},
    werkelijkeToolsMetadata: {},
    availableWerkelijkeTools: [],
    availableWerkelijkeToolsArchived: [],
  },
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
      state.availableWerkelijkeToolsArchived = JSON.parse(
        JSON.stringify(
          state.availableWerkelijkeToolsArchived.concat(
            payload.machineMagazijnen
              .filter((m) => m.werkelijkeTool)
              .map((x) => {
                x.werkelijkeTool.tool.machineGroep =
                  x.werkelijkeTool.tool.machineGroep.naam;
                return x.werkelijkeTool;
              })
          )
        )
      );
    },
    SET_METADATA(state, payload) {
      state.metadata = payload;
    },
    SET_MAGAZIJNEN_METADATA(state, payload) {
      state.magazijnenMetadata = payload;
    },
    SET_AVAILABLE_WERKELIJKE_TOOLS(state, payload) {
      state.availableWerkelijkeTools = payload;
      state.availableWerkelijkeToolsArchived = JSON.parse(
        JSON.stringify(state.availableWerkelijkeToolsArchived.concat(payload))
      );
    },
    SET_WERKELIJKE_TOOLS_METADATA(state, payload) {
      state.werkelijkeToolsMetadata = payload;
    },
    CLEAR_HISTORY(state) {
      state.data = { machineMagazijnen: [] };
      state.metadata = {};
      state.magazijnenMetadata = {};
      state.werkelijkeToolsMetadata = {};
      state.availableWerkelijkeTools = [];
      state.availableWerkelijkeToolsArchived = [];
    },
  },
  actions: {
    getMachine({ commit }, id) {
      return CrudService.getDataById("machines", id)
        .then((response) => {
          if (response.data.success) {
            commit("SET_DATA", response.data.value);
          }

          commit(
            "HANDLE_RESPONSE",
            { response: response.data },
            { root: true }
          );
        })
        .catch((error) => {
          commit("SET_ERROR", error, { root: true });
          throw error;
        });
    },
    getMachinesMetadata({ commit }, isUpdate) {
      return CrudService.getMetadataByName("machines", "form")
        .then(async (response) => {
          await loadOptionsAsync(
            response.data.properties,
            response.data.endpoint
          );
          let exportProcedureNc = response.data.properties.filter(
            (p) => p.variableName == "exportNcProcedureId"
          )[0];
          let exportProcedureTooloffset = response.data.properties.filter(
            (p) => p.variableName == "exportTooloffsetProcedureId"
          )[0];
          let exportProcedureToolcontrole = response.data.properties.filter(
            (p) => p.variableName == "exportToolcontroleExportProcedureId"
          )[0];
          let importProcedureToolmagazijn = response.data.properties.filter(
            (p) => p.variableName == "toolmagazijnImportProcedureId"
          )[0];

          const inleesProceduresResponse = await CrudService.getGridData(
            "inleesprocedures"
          );
          if (inleesProceduresResponse.data.success) {
            const inleesProcedures = inleesProceduresResponse.data.value.map(
              (obj) => {
                return {
                  displayText: obj.naam,
                  key: obj.id,
                  type: obj.type,
                };
              }
            );

            importProcedureToolmagazijn.options = inleesProcedures.filter(
              (x) => x.type === 1
            );
          }

          const exportProceduresResponse = await CrudService.getGridData(
            "exportprocedures"
          );
          if (exportProceduresResponse.data.success) {
            const exportProcedures = exportProceduresResponse.data.value.map(
              (obj) => {
                return {
                  displayText: obj.naam,
                  key: obj.id,
                  type: obj.type,
                };
              }
            );

            exportProcedureNc.options = exportProcedures.filter(
              (x) => x.type === 0
            );
            exportProcedureTooloffset.options = exportProcedures.filter(
              (x) => x.type === 1
            );
            exportProcedureToolcontrole.options = exportProcedures.filter(
              (x) => x.type === 2
            );

            response.data.properties.forEach((p) => {
              if (p.enumValues?.length) {
                p.options = [];
                p.enumValues.forEach((val, index) => {
                  p.options.push({
                    displayText: val,
                    key: index,
                  });
                });
              }
            });
          }
          response.data.properties = response.data.properties.filter(
            (prop) => isUpdate || prop.editable
          );
          commit("SET_METADATA", response.data);
        })
        .catch((error) => {
          commit("SET_ERROR", error, { root: true });
          throw error;
        });
    },
    getMagazijnenMetadata({ commit }) {
      return CrudService.getMetadataByName("MachineMagazijnen", "grid")
        .then((response) => {
          response.data.properties = response.data.properties
            .filter(
              (p) =>
                p.variableName != "machineId" && p.variableName != "machine"
            )
            .map((p) => {
              if (
                p.variableName == "tmId" ||
                p.variableName == "status" ||
                p.variableName == "standaard" ||
                p.variableName == "toolnummer" ||
                p.variableName == "kettingnummer" ||
                p.variableName == "duploCode"
              ) {
                p.editable = true;
              }
              if (p.variableName == "standaard") {
                p.displayType = 10;
                (p.disabledConditions = []), (p.pinned = true);
              }
              return p;
            });

          commit("SET_MAGAZIJNEN_METADATA", response.data);
        })
        .catch((error) => {
          commit("SET_ERROR", error, { root: true });
          throw error;
        });
    },

    getWerkelijkeToolsMetadata({ commit }) {
      return CrudService.getMetadataByName("WerkelijkeTools", "grid")
        .then((response) => {
          commit("SET_WERKELIJKE_TOOLS_METADATA", response.data);
        })
        .catch((error) => {
          commit("SET_ERROR", error, { root: true });
          throw error;
        });
    },

    getAvailableWerkelijkeTools({ commit }, machineId) {
      const params = [
        {
          queryName: "onlyAvailable",
          queryValue: true,
        },
      ];

      if (machineId) {
        params.push({
          queryName: "machineId",
          queryValue: machineId,
        });
      }

      return CrudService.getDataByQueries("werkelijketools", params)
        .then((response) => {
          if (response.data.success) {
            commit("SET_AVAILABLE_WERKELIJKE_TOOLS", response.data.value);
          }
          commit(
            "HANDLE_RESPONSE",
            { response: response.data },
            { root: true }
          );
        })
        .catch((error) => {
          commit("SET_ERROR", error, { root: true });
          throw error;
        });
    },
  },
};
