import axios from "axios";
import store from "../store";

export default () =>
  axios.create({
    baseURL: store.state.baseURL,
    headers: {
      Authorization: `Bearer ${store.state.user.accessToken}`,
      responseType: "arraybuffer",
    },
  });
