import CrudService from "../../services/CrudService";
import WissellijstService from "../../services/WissellijstService";

const namespaced = true;

const state = {
  wissellijstenHistoryMetadata: [],
  wissellijstenHistoryData: {},
  machineNaam: null,
};

const mutations = {
  SET_WISSELLIJSTEN_METADATA(state, payload) {
    state.wissellijstenHistoryMetadata = payload;
  },

  SET_WISSELLIJSTEN_DATA(state, payload) {
    state.wissellijstenHistoryData = payload;
  },

  SET_MACHINE_NAAM(state, payload) {
    state.machineNaam = payload;
  },
};

const actions = {
  getWissellijstenHistoryMetadata({ commit }) {
    CrudService.getMetadataByName("wissellijst", "grid")
      .then((response) => {
        response.data.properties = response.data.properties.filter(
          (p) => p.variableName == "datum"
        );
        commit("SET_WISSELLIJSTEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getWissellijstenHistoryData({ commit }, machineId) {
    WissellijstService.getHistory(machineId)
      .then((response) => {
        commit("SET_WISSELLIJSTEN_DATA", response.data.value);
        commit("SET_MACHINE_NAAM", response.data.value[0].machine.naam);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tabletmachinewissellijstenhistory = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletmachinewissellijstenhistory;
