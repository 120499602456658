<template>
  <div :style="active ? undefined : 'display:none'">
    <CModal :size="size" :visible="active" @close="onCancel">
      <CModalHeader>
        <CModalTitle>Bevestiging</CModalTitle>
      </CModalHeader>
      <CModalBody
        ><span style="white-space: pre-line">{{ bodyText }}</span>
      </CModalBody>
      <CModalFooter>
        <CButton @click="onCancel" color="secondary">Annuleren</CButton>
        <CButton @click="onContinue" color="primary">Doorgaan</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import { toRefs } from "vue";

export default {
  name: "ConfirmationModal",
  props: {
    modalActive: {
      required: true,
    },
    modalBody: {
      required: true,
    },
    size: {
      required: false,
    },
  },
  emits: ["onContinue", "onCancel"],

  setup(props, { emit }) {
    const { modalActive: active, modalBody: bodyText } = toRefs(props);

    function onContinue() {
      emit("onContinue");
    }

    function onCancel() {
      emit("onCancel");
    }

    return {
      onContinue,
      onCancel,
      active,
      bodyText,
    };
  },
};
</script>
