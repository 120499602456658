<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Wissellijsten Historie - {{ machineNaam }}</strong
          >
        </CCardHeader>
        <CCardBody>
          <CButton
            @click="goToWissellistRegels"
            :disabled="wissellijstSelectedRow === undefined"
            color="light"
            class="btn-md mb-2"
          >
            Naar regels
          </CButton>
          &nbsp;
          <CButton @click="onExportToPdf" color="primary" class="btn-md mb-2">
            PDF
          </CButton>
          &nbsp;
          <CButton
            color="primary"
            class="btn-md mb-2"
            style="float: right"
            :disabled="wissellijstSelectedRow === undefined"
            @click="generateTooloffset"
          >
            Tooloffset genereren
          </CButton>
          <dynamic-grid
            @double-clicked="goToWissellistRegels"
            name="MWissellijstenHistory"
            :metadata="wissellijstenHistoryMetadata"
            :data="wissellijstenHistoryData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";
import CrudService from "../../services/CrudService";
import DownloadClient from "@/services/DownloadClient";

export default {
  name: "TabletMachineWissellijstenHistory",
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId } = toRefs(props);

    store.commit("dynamicgrid/SET_NEW_GRID", "MWissellijstenHistory");

    store.dispatch(
      "tabletmachinewissellijstenhistory/getWissellijstenHistoryMetadata"
    );
    store.dispatch(
      "tabletmachinewissellijstenhistory/getWissellijstenHistoryData",
      machineId.value
    );

    const wissellijstenHistoryMetadata = computed(
      () =>
        store.state.tabletmachinewissellijstenhistory
          .wissellijstenHistoryMetadata
    );
    const wissellijstenHistoryData = computed(
      () =>
        store.state.tabletmachinewissellijstenhistory.wissellijstenHistoryData
    );
    const machineNaam = computed(
      () => store.state.tabletmachinewissellijstenhistory.machineNaam
    );

    const wissellijstSelectedRow = computed(() => {
      if (
        store.state.dynamicgrid["MWissellijstenHistory"].selectedRows.length > 0
      ) {
        return store.state.dynamicgrid["MWissellijstenHistory"].selectedRows[0];
      }
      return undefined;
    });

    function generateTooloffset() {
      CrudService.postData(`Wissellijst/GenerateTooloffset`, {
        machineId: machineId.value,
        wissellijstId: wissellijstSelectedRow.value.id,
      })
        .then((response) => {
          store.commit(
            "HANDLE_RESPONSE",
            {
              response: response.data,
              successMessage:
                "De wissellijst is succesvol geëxporteerd als tooloffset bestand.",
              displayMultipleErrors: true,
            },
            { root: true }
          );
          goToWerkvloer();
        })
        .catch(() => {
          store.commit(
            "ADD_TOAST_ERROR",
            "Er is wat misgegaan met het exporteren van de wissellijst als tooloffset bestand.",
            { root: true }
          );
        });
    }

    function goToWerkvloer() {
      router.push({
        name: "werkvloer",
      });
    }

    function goToWissellistRegels() {
      router.push({
        name: "tabletwissellijstenhistoryregels",
        params: {
          wissellijstId: wissellijstSelectedRow.value.id,
        },
      });
    }

    function onExportToPdf() {
      DownloadClient()
        .get(`wissellijst/download/${wissellijstSelectedRow.value.id}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Wissellijst ${machineNaam.value} ${wissellijstSelectedRow.value.datum}`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit(
            "ADD_TOAST_SUCCESS",
            "De wissellijst is opgeslagen als pdf.",
            { root: true }
          );
        })
        .catch(() =>
          store.commit(
            "ADD_TOAST_ERROR",
            "De wissellijst kon niet worden geëxporteerd.",
            { root: true }
          )
        );
    }

    return {
      wissellijstenHistoryMetadata,
      wissellijstenHistoryData,
      wissellijstSelectedRow,
      goToWerkvloer,
      cilArrowLeft,
      goToWissellistRegels,
      machineNaam,
      generateTooloffset,
      onExportToPdf,
    };
  },
};
</script>
