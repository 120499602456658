const namespaced = true;

const state = {};

const mutations = {
  SET_MODAL_VALUES(state, { payload, identifier }) {
    state[identifier].modalValues = payload;
  },

  SET_MODAL_METADATA(state, { payload, identifier }) {
    state[identifier].modalMetadata = payload;
  },

  SET_NEW_MODAL(state, identifier) {
    state[identifier] = {
      modalMetadata: {},
      modalValues: [],
    };
  },
};

const actions = {};

const dynamicmodal = {
  state,
  mutations,
  actions,
  namespaced,
};

export default dynamicmodal;
