<template>
  <input
    class="form-control"
    @change="onInputChanged"
    style="margin-bottom: 5px"
    v-model="folder"
  />
  <input class="form-control" @change="onInputChanged" v-model="fileName" />
  <CButton @click="onOpenTreeView">Kies een bestand of folder</CButton>
  <div :style="active ? undefined : 'display:none'">
    <CModal size="lg" :visible="active" @close="onCancel">
      <CModalHeader>
        <CModalTitle style="width: 100%">
          <span>{{ selected ?? "Kies een folder of bestand" }}</span>
        </CModalTitle>
        <CButton
          @click="onContinue"
          :disabled="!selected && !focussed"
          color="primary"
          class="btn-md me-1"
          style="float: right"
        >
          Doorgaan
        </CButton>
      </CModalHeader>
      <CModalBody>
        <TreeView
          :selected="modelValue"
          :nodes="nodes"
          @selection-changed="onSelectionChanged"
          @double-clicked="onDoubleClick"
          :extensions="extensions"
        />
      </CModalBody>
    </CModal>
  </div>
</template>

<script>
import { defineComponent, ref, toRefs, watch } from "vue";
import TreeView from "./TreeView.vue";
import CrudService from "../services/CrudService";
import { createUrl } from "@/helpers/apiHelper.js";

export default defineComponent({
  components: {
    TreeView,
  },
  props: {
    modelValue: {
      required: false,
    },
    extensionsConfig: {
      required: false,
    },
    requiredVariable: {
      required: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const {
      modelValue: modelValue,
      extensionsConfig: extensionsConfig,
      requiredVariable: requiredVariable,
    } = toRefs(props);

    const active = ref(false);
    const extensions = ref([]);
    const selected = ref(undefined);

    const fileName = ref(undefined);
    const folder = ref(undefined);

    watch(selected, () => {
      const lastPart = selected.value?.split("\\")?.pop();
      if (lastPart && lastPart.includes(".")) fileName.value = lastPart;
      else fileName.value = undefined;

      if (fileName.value) {
        folder.value = selected.value?.substring(
          0,
          selected.value.lastIndexOf("\\")
        );
      } else {
        folder.value = selected.value;
      }
    });

    watch(
      modelValue,
      () => {
        selected.value = modelValue.value;
      },
      { immediate: true }
    );

    watch(
      requiredVariable,
      () => {
        if (!extensionsConfig.value) return;
        let extensionsUrl = createUrl(
          extensionsConfig.value,
          undefined,
          requiredVariable.value
        );
        if (extensionsUrl) {
          CrudService.getGridData(extensionsUrl).then((response) => {
            if (response.data) {
              extensions.value = response.data;
              loadRoot();
            }
          });
        }
      },
      { immediate: true }
    );

    const nodes = ref(undefined);

    function loadRoot() {
      CrudService.getDataByQuery(
        "files/folderstructure",
        "extensions",
        extensions.value
      ).then((response) => {
        nodes.value = response.data;
      });
    }

    function onCancel() {
      active.value = false;
    }

    const focussed = ref(undefined);
    function onSelectionChanged(node) {
      focussed.value = node;
    }

    function onDoubleClick(node) {
      selected.value = node;
      onContinue();
    }

    function onContinue() {
      selected.value = focussed.value;
      active.value = false;
      emit("update:modelValue", selected.value);
    }

    function onOpenTreeView() {
      if (!folder.value) {
        active.value = true;
        return;
      }
      if (folder.value.endsWith("\\")) {
        folder.value = folder.value.substring(0, folder.value.length - 1);
      }

      const lastBackslash = folder.value.lastIndexOf("\\");
      const lastPart =
        lastBackslash > 0
          ? folder.value.substring(lastBackslash + 1)
          : undefined;
      const hasFile = lastPart && lastPart.includes(".");

      if (hasFile) {
        fileName.value = lastPart;
        folder.value = folder.value.substring(0, lastBackslash);
      }
      selected.value =
        (folder.value ?? "") +
        (folder.value ? "\\" : "") +
        (fileName.value ?? "");
      emit("update:modelValue", selected.value);
      active.value = true;
    }

    function onInputChanged() {
      let path = "";
      if (folder.value?.length > 0) path += folder.value;
      if (fileName.value?.length > 0) {
        if (path.endsWith("\\") || path.endsWith("/")) {
          path += fileName.value;
        } else {
          path += "\\" + fileName.value;
        }
      }
      emit("update:modelValue", path);
    }

    return {
      active,
      nodes,
      onCancel,
      selected,
      onContinue,
      onDoubleClick,
      onSelectionChanged,
      focussed,
      fileName,
      folder,
      extensions,
      onOpenTreeView,
      onInputChanged,
    };
  },
});
</script>
