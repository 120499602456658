import CrudService from "../../services/CrudService";

const namespaced = true;

const state = {
  versletenHistoryMetadata: [],
  versletenHistoryData: {},
  machineNaam: null,
};

const mutations = {
  SET_VERSLETEN_METADATA(state, payload) {
    payload.properties.push({
      variableName: "select",
      displayName: "Kies",
      displayType: 10,
    });
    state.versletenHistoryMetadata = payload;
  },

  SET_VERSLETEN_DATA(state, payload) {
    state.versletenHistoryData = payload;
  },

  SET_MACHINE_NAAM(state, payload) {
    state.machineNaam = payload;
  },
};

const actions = {
  getVersletenHistoryMetadata({ commit }) {
    CrudService.getMetadataByName("vervangmoment", "grid")
      .then((response) => {
        commit("SET_VERSLETEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getVersletenHistoryData({ commit }, machineId) {
    CrudService.getDataById("VervangMoment/bymachine", machineId)
      .then((response) => {
        commit("SET_MACHINE_NAAM", response.data.value[0].machine.naam);
        commit("SET_VERSLETEN_DATA", response.data.value);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tabletversletenhistory = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletversletenhistory;
