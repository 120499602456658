import ApiClient from "./ApiClient";

export default {
  postTool(tool) {
    return ApiClient().post("/tools", tool);
  },

  putTool(tool, id) {
    return ApiClient().put(`/tools/${id}`, tool);
  },

  uploadImage(toolId, file) {
    const formData = new FormData();
    formData.append("file", file);
    return ApiClient().post(`/files?toolId=${toolId}`, formData);
  },

  deleteTool(id) {
    return ApiClient().delete(`/tools/${id}`);
  },
};
